
import FeatureFilter from "@arcgis/core/layers/support/FeatureFilter.js";
import LayerIds from "../config/layers/LayerIds";
//import { SET_BASINS, SET_COUNTIES } from "./locations";
import { SET_FILTER, SetPanelFilter, TOGGLE_FILTER_VALUE, UpdateCountyTotals } from "./filters";
//import { SET_DEFAULT_FILTER, SET_RADIUS } from "./types";
import { createListenerMiddleware } from "@reduxjs/toolkit";
import FieldNames from "../config/layers/FieldNames";
import { getWellsAndPermitList } from "../../store/actions/wells-rigs-action";

export const filterMiddleware = createListenerMiddleware();

const updateLayers = (action: any, state: any, updateFilters: boolean = true) => {
    const layers = state.filters.layers;
    const layerId = action.payload.find((f:any) =>  f.layerId === LayerIds.WellData)?.layerId;
    const layer = state.esri.map.layers.find((l: { title: string; }) => l.title === LayerIds.WellData);
    let showRigs = true;
    let defaultFilter: FeatureFilter;
    let rigFilter: FeatureFilter;
    if (layer && layerId) {
        const rigFilters:string[] = [];
        const layerFilters = Object.keys(layers[layerId])
            .map(field => {
                const ids = layers[layerId][field];
                if (Array.isArray(ids) && ids.length > 0) {
                    if (ids[0] == -1) {
                        return `${field} IN (NULL)`;
                    } else if (
                        typeof ids[0] == "string" &&
                        ids[0].startsWith("DATERANGE")
                    ) {
                        const fields: string[] = field.split("|");
                        const orClauses: string[] = [];
                        const d1 = new Date(ids[1]);
                        const d2 = new Date(ids[2]);
                        //This works for the grammar parser for the ESRI where clause, but does not filter correctly...
                        fields.forEach(f => {
                            orClauses.push(
                                `${f} >= DATE '${d1.getFullYear()}-${d1.getDate()}-${d1.getMonth()}' AND ${f} <= DATE '${d2.getFullYear()}-${d2.getDate()}-${d2.getMonth()}'`
                            );
                            //`(${f} >= TIMESTAMP '${new Date(ids[1]).toLocaleString().replace(",","")}') AND (${f} <= TIMESTAMP '${new Date(ids[2]).toLocaleString()}')`
                            //`${f} BETWEEN ${new Date(ids[1]).getTime()} AND ${new Date(ids[1]).getTime()}`
                            //`${f} >= DATE '${new Date(ids[1]).toLocaleString()}' AND ${f} <= DATE '${new Date(ids[2]).toLocaleString()}'`
                            //`${f} >= TIMESTAMP '${new Date(ids[1]).toLocaleString()}'`
                        });
                        const formattedDateClause = orClauses.length > 1 ? `(${orClauses.join(" OR ")})` : orClauses[0];
                        return formattedDateClause;
                    } else if (
                            typeof ids[0] == "string" &&
                            ids[0].startsWith("NUMBERRANGE")
                        ) {
                            const fields: string[] = field.split("|");
                            const orClauses: string[] = [];
                            fields.forEach(f => {
                                orClauses.push(
                                    `${f} >=  '${ids[1]}' AND ${f} <= ${ids[2]}`
                                );
                            });
                            const formattedDateClause = orClauses.length > 1 ? `(${orClauses.join(" OR ")})` : orClauses[0];
                            return formattedDateClause;
                    } else if (typeof ids[0] == "number") {
                        if (field === FieldNames.wellDataPointsTypeId) {
                            if(ids.indexOf(-100) === -1) {
                                showRigs = false;
                            }
                        }
                        //return "";
                        return `${field} IN ('${ids.join("','")}')`;
                    } else {
                        if (FieldNames.rigFields.includes(field)) {
                            rigFilters.push(`${field} IN ('${ids.join("','")}')`);
                            //return ids.map((value) => `${field} LIKE '%${value}%'`).join(' OR ');
                        }
                        //return "";
                        return `${field} IN ('${ids.join("','")}')`;
                    }
                } else if (Array.isArray(ids) && ids.length == 0) {
                    return undefined; // array length of 0 represents no filter - it's not an error condition
                }
                console.log(`Error adding filter for field ${field}, value: ${ids}`);
                return undefined;
            })
            .filter(f => !!f); // Remove undefined values (errored out filters)
        const defaultFilterDef = state.defaultFilters.filterDefs[layerId];
        if (defaultFilterDef) {
            defaultFilter = new FeatureFilter({
                distance: defaultFilterDef.distance,
                geometry: defaultFilterDef.geometry,
                spatialRelationship: defaultFilterDef.spatialRelationship,
                units: defaultFilterDef.units,
                where: defaultFilterDef.hasOwnProperty("where")
                    ? `${defaultFilterDef.where} AND ${layerFilters.join(" AND ")}`
                    : layerFilters.join(" AND ")
            });
        } else {
            defaultFilter = new FeatureFilter({
                where: layerFilters.length > 1 ? layerFilters.join(" AND ") : layerFilters[0]
            });
            rigFilters.length > 1
                ? rigFilter = new FeatureFilter({ 
                    where: rigFilters.length > 1 ? rigFilters.join(" AND ") : rigFilters.join("")
                })
                : rigFilter = new FeatureFilter()

        }
    }

    const rigLayer = state.esri.map.layers.find((l: { title: string; }) => l.title === LayerIds.RigsData);
    if (rigLayer) {
        const mapView = state.esri.mapView;
        mapView.whenLayerView(rigLayer).then((layerView: __esri.FeatureLayerView) => {
            layerView.filter = showRigs && rigFilter
                ? rigFilter
                : new FeatureFilter({where: "1=0"})
        
        });
    }
}

//Removed middleware due to AWS API Filtering taking place now, no longer necessary
//to filter on client : 7/3/2024
filterMiddleware.startListening({
    actionCreator: SET_FILTER,
    effect: async (action, listenerApi) => {
        const state:any = listenerApi.getState();
        updateLayers(action, state);
    }
})

filterMiddleware.startListening({
    actionCreator: TOGGLE_FILTER_VALUE,
    effect: async (action, listenerApi) => {
        const state:any = listenerApi.getState();
        updateLayers(action, state);
    }
})

// filterMiddleware.startListening({
//     type: 'wells-rigs-action/getWellsAndPermitList',
//     effect: async (action, listenerApi) => {
//         const state:any = listenerApi.getState();
//         UpdateMapLegendFromFilter(action, state);
//     }
// })


