import React, { useEffect, useLayoutEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { getBasinCountyDetails, handleLastCountyBasinName, removeMultiCountyAndBasin } from "../store/actions/cart-select-basin-county-actions";
import { CountyDetailsObject } from "../models/redux-models";
import BasinSuggestionModal from "../common/Modal/BasinSuggestionModal";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router";
import { MONTHS, calculateDiscount } from "../../utils/helper";
import { hideSiteLoader } from "../store/actions/modal-actions";

function SearchList() {
    const {
        cartSelectBasinCounty: {
            basinSearchList,
            countySearchList,
            cartListItems,
            lastCountyBasinName,
            cartItemsTotal,
            yearly,
            yearly_discount_percent
        },
        auth: {
            user: { access_token, counties_price_below_of_basin, company_data: { company_id }, company_configs: { free_trial_period_enabled, trial_expired } },
        },
        subscriptionSettings: { go_nation, upgrade_subscription }
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState<{
        showSuggestionModal: boolean,
        countyListOfSameBasin: CountyDetailsObject[],
        priceNearToBasin: boolean,
        go_nation_flag: boolean
    }>({
        showSuggestionModal: false,
        countyListOfSameBasin: [],
        priceNearToBasin: false,
        go_nation_flag: false
    });
    const { showSuggestionModal, countyListOfSameBasin, priceNearToBasin, go_nation_flag } = state;
    const checkGoNational = () => {
        if (cartItemsTotal && cartListItems.length && !cartListItems.find((item) => "plan_name" in item && item.plan_name === "National") && go_nation
            && (
                ((cartItemsTotal - Number(calculateDiscount(cartItemsTotal, yearly_discount_percent))) * (yearly ? MONTHS : 1)) >= ((go_nation.price - (Number(calculateDiscount(go_nation.price, counties_price_below_of_basin)))) * (yearly ? MONTHS : 1))
            )
        ) {
            setState((prev) => ({ ...prev, showSuggestionModal: true, go_nation_flag: true }));
            return
        }
    }

    useEffect(() => {
        if (showSuggestionModal) {
            dispatch(hideSiteLoader());
        }
    }, [showSuggestionModal])

    useEffect(() => {

        if (lastCountyBasinName) {
            let countyListOfSameBasin = (cartListItems.filter((_obj) => "county_name" in _obj && lastCountyBasinName === _obj.county_basin) as CountyDetailsObject[]);
            let totalCountyPrice = [...countyListOfSameBasin].map(_val => _val.price).reduce((accumulator, currentValue) => accumulator + currentValue,
                0)

            if (countyListOfSameBasin[0]?.basin_price && totalCountyPrice >= countyListOfSameBasin[0]['basin_price']) {
                setState((prev) => ({ ...prev, showSuggestionModal: true, countyListOfSameBasin: countyListOfSameBasin }))
                return
            }

            //price - sum of couty price <= % value that come from backend=======>for second pop
            if (countyListOfSameBasin[0]?.basin_price && countyListOfSameBasin[0]['basin_price'] - totalCountyPrice <= ((countyListOfSameBasin[0]['basin_price'] * counties_price_below_of_basin) / 100)) {
                setState((prev) => ({ ...prev, showSuggestionModal: true, countyListOfSameBasin: countyListOfSameBasin, priceNearToBasin: true }))
                return
            }
            // dispatch(handleLastCountyBasinName(""));
        }
        checkGoNational()

        // eslint-disable-next-line 
    }, [JSON.stringify(cartListItems), go_nation])

    useEffect(() => {
        if (location?.state?.add_go_national) {
            dispatch(
                getBasinCountyDetails(
                    access_token,
                    {
                        category:
                            "nation",
                        search: `Go National`,
                    }
                )
            );
            window.history.replaceState({}, document.title)
        }
    }, [])

    return (
        <>
            {(basinSearchList.length > 0 || countySearchList.length > 0) && (
                <div className="searchList scrollSection">
                    {/* basinSearchList */}
                    {basinSearchList.length > 0 && (
                        <>
                            <h3>BASIN</h3>
                            <ul>
                                {basinSearchList.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            {item.basin_name}
                                            <a
                                                className={`add-cart ${cartListItems.filter(
                                                    (_item) =>
                                                        "basin_name" in
                                                        _item &&
                                                        _item.basin_name ===
                                                        item.basin_name
                                                ).length > 0
                                                    ? "added"
                                                    : ""
                                                    }`}
                                                href="void(0)"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (!company_id) {
                                                        toast.info("Please add your company details.");
                                                        navigate("/company-settings");
                                                        return
                                                    }
                                                    if (!trial_expired && free_trial_period_enabled && Array.isArray(cartListItems) && cartListItems.length && !upgrade_subscription) {
                                                        toast.info("Only one basin selection is permitted under the free trial.");
                                                        return
                                                    }
                                                    if (cartListItems.filter(
                                                        (_item) =>
                                                            "basin_name" in
                                                            _item &&
                                                            _item.basin_name ===
                                                            item.basin_name
                                                    ).length === 0) {
                                                        dispatch(
                                                            getBasinCountyDetails(
                                                                access_token,
                                                                {
                                                                    category:
                                                                        "basin",
                                                                    search: `${item.basin_name}`,
                                                                }
                                                            )
                                                        );
                                                    }

                                                }}
                                            >
                                                <i className="fa-solid fa-circle-plus"></i>{" "}
                                                {cartListItems.filter(
                                                    (_item) =>
                                                        "basin_name" in _item &&
                                                        _item.basin_name ===
                                                        item.basin_name
                                                ).length > 0
                                                    ? "Added"
                                                    : "Add to Cart"}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </>
                    )}

                    {/* countySearchList */}
                    {countySearchList.length > 0 && (
                        <ul>
                            {countySearchList.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <h3>{item.state_name}</h3>
                                        {item.county_name.map(
                                            (_item, _index) => {
                                                return (
                                                    <li key={_index}>
                                                        {_item}
                                                        <a
                                                            // className="add-cart added"
                                                            className={`add-cart ${cartListItems.filter(
                                                                (obj) =>
                                                                    "county_name" in
                                                                    obj &&
                                                                    obj.state_abbr ===
                                                                    item.iso_code &&
                                                                    _item ===
                                                                    obj.county_name
                                                            ).length > 0
                                                                ? "added"
                                                                : ""
                                                                }`}
                                                            href="void(0)"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (!company_id) {
                                                                    toast.info("Please add your company details.");
                                                                    navigate("/company-settings");
                                                                    return
                                                                }
                                                                if (cartListItems.filter(
                                                                    (obj) =>
                                                                        "county_name" in
                                                                        obj &&
                                                                        obj.state_abbr ===
                                                                        item.iso_code &&
                                                                        _item ===
                                                                        obj.county_name
                                                                ).length ===
                                                                    0) {
                                                                    dispatch(
                                                                        getBasinCountyDetails(
                                                                            access_token,
                                                                            {
                                                                                category:
                                                                                    "county",
                                                                                search: `${_item}`,
                                                                                state: `${item.iso_code}`,
                                                                            }
                                                                        )
                                                                    );
                                                                }

                                                            }}
                                                        >
                                                            <i className="fa-solid fa-circle-plus"></i>{" "}
                                                            {cartListItems.filter(
                                                                (obj) =>
                                                                    "county_name" in
                                                                    obj &&
                                                                    obj.state_abbr ===
                                                                    item.iso_code &&
                                                                    _item ===
                                                                    obj.county_name
                                                            ).length > 0
                                                                ? "Added"
                                                                : "Add to Cart"}
                                                        </a>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </ul>
                    )}
                </div>
            )}
            {showSuggestionModal && (
                <BasinSuggestionModal
                    show={showSuggestionModal}
                    go_nation_flag={go_nation_flag}
                    handleClose={() => {
                        if (go_nation_flag) {
                            setState((prev) => ({ ...prev, showSuggestionModal: false, go_nation_flag: false }))
                            return
                        }
                        dispatch(handleLastCountyBasinName(""));
                        setState((prev) => ({ ...prev, showSuggestionModal: false, countyListOfSameBasin: [], ...(priceNearToBasin && { priceNearToBasin: false }) }))
                        checkGoNational()
                    }}
                    priceNearToBasin={priceNearToBasin}
                    handleProceedToBtnClick={() => {
                        if (go_nation_flag) {
                            dispatch(
                                getBasinCountyDetails(access_token, {
                                    search: "Go National",
                                    category: "nation",
                                }))
                            setState((prev) => ({ ...prev, showSuggestionModal: false, go_nation_flag: false }))
                            return
                        }
                        dispatch(removeMultiCountyAndBasin(access_token, {
                            item_id: countyListOfSameBasin.map((_item) => _item.id),
                            item_type: 2,
                            basin_name: lastCountyBasinName,
                        }))
                        dispatch(handleLastCountyBasinName(""));
                        setState((prev) => ({ ...prev, showSuggestionModal: false, countyListOfSameBasin: [], ...(priceNearToBasin && { priceNearToBasin: false }) }))
                    }} />
            )}
        </>
    );
}

export default SearchList;
