import React, { useEffect, useState } from 'react'
import { deleteSavedSearchFilters, fetchSavedSearchFilters, handleSavedFilter, updateFilterName } from '../store/actions/cart-basin-to-county-actions';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import Scrollbars from 'react-custom-scrollbars';
import { toast } from 'react-toastify';

const SaveSearchFiltersComp = ({ saveSearchFiltersFn, fetchFilterById }: { saveSearchFiltersFn: () => void, fetchFilterById: (id: number) => void }) => {
    const dispatch = useAppDispatch();
    const { cartBasinToCounty: { savedFilter: { openSavedFilter, saveFiltersDataLoading, saveFiltersList, filterID } } } = useAppSelector(state => state);
    useEffect(() => {
        if (openSavedFilter && saveFiltersList === null) {
            dispatch(fetchSavedSearchFilters())
        }
    }, [openSavedFilter])
    const [filterName, setFilterName] = useState("")


    if (!openSavedFilter) {
        return <></>
    }

    return (
        <div className={`dropdownMenu ${openSavedFilter ? "show" : "isHide"}`}>
            <div className={`saved-filter-as`}><a href="void(0)" className={`${saveFiltersDataLoading ? "disabled" : ''}`} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (saveFiltersDataLoading) {
                    return
                }
                if (saveFiltersList && saveFiltersList?.length === 10) {
                    toast.info(`Maximum 10 filter configurations allowed per user.`);
                    return
                }
                saveSearchFiltersFn();
                // dispatch(handleSavedFilter({ openSavedFilter: false }))
            }}>Save filter as...</a></div>
            <div className="saved-filter-heading" onClick={e => { e.stopPropagation() }}>Saved Filters</div>
            <Scrollbars
                autoHeight
                autoHeightMin={0}
                autoHeightMax="25rem"
                renderThumbVertical={(props) => (
                    <div {...props} className="thumb-vertical" />
                )}
                renderTrackVertical={(props) => (
                    <div {...props} className="track-vertical" />
                )}
            >

                <ul onClick={(e) => e.stopPropagation()}>
                    {saveFiltersDataLoading ? <li>
                        <div className="normalCase" onClick={(e) => e.stopPropagation()}>
                            <div className='nofoundrecord'>Loading...</div>
                        </div>
                    </li> : Array.isArray(saveFiltersList) && saveFiltersList?.length === 0 ? <li> <div className="normalCase" onClick={(e) => e.stopPropagation()}>
                        <div className='nofoundrecord norecord'>No filter are saved.</div>
                    </div> </li> : <
                        >
                        {
                            saveFiltersList?.map((item, index) => {
                                return <li key={`${index}`} className='cursor'>
                                    <div className={`editCase ${filterID === item['id'] ? "open" : "isHide"}`}>
                                        <div className="inputFld">
                                            <input type={'text'}
                                                name={`filterName${index}`}
                                                className="form-control"
                                                defaultValue={item['search_name']}
                                                onBlur={(e) => {
                                                    setFilterName(e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="action">
                                            <a href="void:(0)"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    if (filterName.trim() === item['search_name'].trim()) {
                                                        dispatch(handleSavedFilter({ openSavedFilter: false, filterID: 0 }))
                                                        return;
                                                    }

                                                    if (filterName.trim() === "") {
                                                        toast.error("Please enter the filter name.");
                                                    } else {
                                                        dispatch(updateFilterName({ search_name: filterName.trim(), id: item['id'] }))
                                                    }
                                                }}
                                                className="green"><i className="fa-solid fa-check"></i></a>

                                            <a href="void:(0)" onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                dispatch(handleSavedFilter({ filterID: 0 }))
                                            }
                                            } className="red"><i className="fa-solid fa-xmark"></i></a>
                                        </div>
                                    </div>
                                    <div className={`normalCase ${filterID === item['id'] ? "isHide" : "open"}`} onClick={(e) => {
                                        fetchFilterById(item['id'])
                                        dispatch(handleSavedFilter({ openSavedFilter: false }))
                                    }}>
                                        {item['search_name']}
                                        <div className="action">
                                            <a href="void:(0)" onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                dispatch(handleSavedFilter({ filterID: item['id'] }))
                                            }
                                            }><i className="fa-solid fa-pen-to-square"></i></a>
                                            <a href="void:(0)" onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                dispatch(deleteSavedSearchFilters({ id: item['id'] }))
                                            }}><i className="fa-solid fa-trash-can"></i></a>
                                        </div>
                                    </div>
                                </li>
                            })
                        }
                    </>
                    }

                </ul>
            </Scrollbars>
        </div >
    );
}

export default SaveSearchFiltersComp