import React, { useRef, useEffect, useState, useMemo } from "react";
import { BASINS, COUNTIES } from "./redux/types";

import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import Sketch from "@arcgis/core/widgets/Sketch";
import Expand from "@arcgis/core/widgets/Expand";
import Compass from "@arcgis/core/widgets/Compass";
import Zoom from "@arcgis/core/widgets/Zoom";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import * as projection from "@arcgis/core/geometry/projection.js";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import FeatureEffect from "@arcgis/core/layers/support/FeatureEffect.js";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine.js";
import * as colorRendererCreator from "@arcgis/core/smartMapping/renderers/color";

import { arcgisToGeoJSON, geojsonToArcGIS } from "@terraformer/arcgis"
import { geojsonToWKT, wktToGeoJSON } from "@terraformer/wkt";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import esriConfig from "@arcgis/core/config";
import Color from "@arcgis/core/Color.js";
import { IBaseMapConfig, AoIBaseMapConfig } from "./config/BaseMapConfig";
import LayerIds from "./config/layers/LayerIds";

import { INIT_MAP, NEW_AOI, NEW_PROJECT, clearCurrentAoi } from "./redux/esri";

import "../../App.css";


import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import geometry, { Extent, Multipoint, Polygon, SpatialReference } from "@arcgis/core/geometry";
import Graphic from "@arcgis/core/Graphic";
import { fetchSubscriptionData } from "../store/actions/subscription-settings-actions";
import Renderers from "./config/carto/Renderers";
import FieldNames from "./config/layers/FieldNames";
import { hideSiteLoader, showSiteLoader } from "../store/actions/modal-actions";
import FilterPanel from "./FilterPanel";
import { fetchAoiList, fetchAoiStats, setLastAddedAoiName } from "../store/actions/aoi-actions";
import { AoiListObject, CountyStatObject, ProductionDataObj, RigsDataObj, WellsAndPermitsObject } from "../models/redux-models";
import { drillTypeOption, productType, wellStatusOption, wellTypeOption } from "../cartBasinToCounty/CartBasinConstant";
import { IFilter, SET_FILTER, SetPanelFilter, UpdateCountyTotals } from "./redux/filters";
import { getMapData, handleSelectedAoiData, handleSelectedWellRigOrPermitUIDList, handleRearrange } from "../store/actions/wells-rigs-action";
import BaseLayers from "./config/layers/BaseLayers";
import FeatureFilter from "@arcgis/core/layers/support/FeatureFilter";
import { isInstanceOfRigsDataObj } from "../common/Map/helper"


esriConfig.apiKey = "AAPTxy8BH1VEsoebNVZXo8HurIFrPFgGey8U5XUPUcDYvjXPcwJFvC-EcuRPUYkdkHpWzeWrBu-ywGYw_XJpwyPKuDCgEZVaGQVe9gBpdS_C3DfekYs3ioo9xtE6Q41Kmm9vRmM_LxJ2u59C5pAp7-3CuJb5ABXvWfsrMmqJQx8rZBSIZCznWMdn6f2CR6G7S0rNVTeXt80ry5xpBfCjD6Wma-Y8WCpQIEyJ9ZIzLT6u79DaUPG8PpDSN8_BhsynN6sOqszqKXsSMNIKRp62GXy_WA..AT1_8r1J5dEI";
esriConfig.assetsPath = "/static/home/js/map/assets";
let webmap: WebMap;
let mapview: MapView;
let sketch: Sketch;
let countyLayerView: __esri.FeatureLayerView;
let wellsLayerView: __esri.FeatureLayerView;
let mapViewNavigationHandlers: __esri.Handle[] = [];

const defaultQuery = "1=1";
const excludedEffect = "drop-shadow(1px, 1px, 2px) grayscale(90%) opacity(30%) contrast(200%)";

interface IHighlight {
    attributes: any,
    remove: any
}

interface IAWSEXtent {
    tile: number,
    xmin: number,
    xmax: number,
    ymin: number,
    ymax: number,
    spatialReference: { wkid: number };
}

interface ISubscriptionMapData {
    type: number,
    name: string,
    state: string
}

interface IAoIMapProps {
    allowCreateAoI: boolean;
}

function AoIMap(props: IAoIMapProps) {
    const { allowCreateAoI } = props;
    const dispatch = useAppDispatch();
    const aoiMapDiv = useRef(null);
    const [config, setConfig] = useState({});
    const [mapSet, setMapSet] = useState(false);
    const [layersLoaded, setLayersLoaded] = useState(false);
    const [wellLayerLoaded, setWellLayerLoaded] = useState(false);
    const [screenPoint, setScreenPoint] = useState({});
    const prevScreenPoint = useRef({});
    const [highlights, setHighlights] = useState<IHighlight[]>([]);
    const [selectedHighlight, setSelectedHighlight] = useState<IHighlight | null>(null);
    const [currentConfirmSketch, setCurrentConfirmSketch] = useState<Graphic>();
    const [currentSubscriptions, setCurrentSubscriptions] = useState<ISubscriptionMapData[]>([]);
    const [subscriptionQuery, setSubscriptionQuery] = useState<string>('')
    const [wellDataQuery, setWellDataQuery] = useState<string>('')
    const [saveCurrentType, setSaveCurrentType] = useState('');
    const [showZoomMessage, setShowZoomMessage] = useState<Boolean>(true);
    const [popupOpen, setPopupOpen] = useState(false);
    const [countiesLoaded, setCountiesLoaded] = useState(false);
    const [mapLoading, setMapLoading] = useState(true);
    const [popupSelectedIndex, setPopupSelectedIndex] = useState(0);
    const [awsSmallerExtents, setAWSSmallerExtents] = useState<IAWSEXtent[]>([]);
    const [mapKeyVisible, setMapKeyVisible] = useState<boolean>(true);
    const [zoomToWells, setZoomToWells] = useState<boolean>(false);
    const [graphicLayersLoading, setGraphicLayersLoading] = useState<boolean>(false);
    const [checkAndSetFeatures, setCheckandSetFeatures] = useState<string[] | undefined>(undefined);
    const [mapHasLoadedOnce, setMapHasLoadedOnce] = useState<boolean>(false);
    const filterPanelRef = useRef(null);
    const zoomRef = useRef<__esri.Zoom | null>(null);

    const {
        aoi: { aoiDataLoading, savedAoiData, lastAddedAoiName },
        auth: {
            user: { access_token },
        },
        filters: { layers, panelFilters, countyTotals, wellsEnabled, rigsEnabled, aoiEnabled, plssTlssEnabled, countiesEnabled },
        locations: { selectedType },
        modal: { createAoiModal },
        wellsAndRigs: { selectedRowId, tabIndex, showTable, selectedWellRigOrPermitUIDList, selectedWellRigOrPermitList, mapData, mapLineData, wellsData: { data: wellsDataList }, selectedAoiData, showTableLoader, downloadCol },
        subscriptionSettings: { loadSubscriptionData, subscriptionData },
    } = useAppSelector((state) => state);

    const getDontGrayOutOtherWells = () => {
        let dontGrayOutOtherWells = false
        /* If the selected uid is a rig, rather than a well, then we do not want to gray
        out the wells.  Here, I'm looking at the list of full objects, and seeing if the 
        first one is an instance of a RigsDataObj.  In that case, we should not gray out
        the wells */
        if (selectedWellRigOrPermitList && selectedWellRigOrPermitList.length > 0 && isInstanceOfRigsDataObj(selectedWellRigOrPermitList[0])) {
            dontGrayOutOtherWells = true
        }
        return dontGrayOutOtherWells
    }

    useEffect(() => {
        if (aoiMapDiv.current) {
            /**
             * Initialize config
             */
            setConfig(new AoIBaseMapConfig());
        }
    }, []);

    useEffect(() => {
        if (layersLoaded) {
            const pLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellsDataGraphics) as FeatureLayer;
            if (pLayer) {
                pLayer.visible = wellsEnabled;
            }
            const lLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellLineGraphics) as FeatureLayer;
            if (lLayer) {
                lLayer.visible = wellsEnabled;
            }
        }
    }, [wellsEnabled]);

    useEffect(() => {
        if (layersLoaded) {
            const pLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.RigsData) as FeatureLayer;
            if (pLayer) {
                pLayer.visible = rigsEnabled;
            }
        }
    }, [rigsEnabled]);

    useEffect(() => {
        if (layersLoaded) {
            const pLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.AoIData) as FeatureLayer;
            if (pLayer) {
                pLayer.visible = aoiEnabled;
            }
        }
    }, [aoiEnabled]);

    useEffect(() => {
        if (layersLoaded) {
            const cLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.CountyStats) as FeatureLayer;
            if (cLayer) {
                cLayer.visible = countiesEnabled;
            }
            const graphicsCLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.CountyStatsGraphic) as FeatureLayer;
            if (graphicsCLayer) {
                graphicsCLayer.visible = countiesEnabled;
            }
            const countyGraphicsLayerView = mapview.allLayerViews.find((lv) => lv.layer.title === LayerIds.CountyStatsGraphic);
            if (countyGraphicsLayerView) {
                countyGraphicsLayerView.visible = countiesEnabled;
            }
        }
    }, [countiesEnabled]);

    // useEffect(() => {
    //     if (layersLoaded) {
    //         const pLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.PLSS) as FeatureLayer;
    //         console.log(pLayer)
    //         if (pLayer) {
    //             pLayer.visible = plssTlssEnabled;
    //         }
    //     }
    // }, [plssTlssEnabled]);

    const disableMapNavigation = (_mapView: __esri.MapView, disable: boolean) => {
        // First remove all existing handlers
        mapViewNavigationHandlers.forEach(handler => handler?.remove());
        mapViewNavigationHandlers = [];

        if (!disable) {
            return;
        }

        function stopEvtPropagation(e: any) {
            e.stopPropagation();
        }

        mapViewNavigationHandlers.push(
            _mapView.on("mouse-wheel", stopEvtPropagation),
            _mapView.on("double-click", stopEvtPropagation),
            _mapView.on("double-click", ["Control"], stopEvtPropagation),
            _mapView.on("drag", stopEvtPropagation),
            _mapView.on("drag", ["Shift"], stopEvtPropagation),
            _mapView.on("drag", ["Shift", "Control"], stopEvtPropagation),
            _mapView.on("key-down", (e) => {
                const prohibitedKeys = ["+", "-", "Shift", "_", "=", "ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft"];
                const keyPressed = e.key;
                if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                    e.stopPropagation();
                }
            })
        )
    };
    

    useEffect(() => {
        //@ts-ignore
        if (config !== null && config.hasOwnProperty("mapConfig") && !mapSet && subscriptionQuery.length > 0) {
            setMapSet(true);
            //@ts-ignore
            const { mapConfig } = config;
            webmap = new WebMap({
                portalItem: {
                    id: mapConfig.webMapId
                }
            });

            const graphicsLayer = new GraphicsLayer({ title: LayerIds.AoiSketch });
            webmap.add(graphicsLayer);

            const aoiGraphicsLayer = new GraphicsLayer({ title: LayerIds.AoIData });
            webmap.add(aoiGraphicsLayer, webmap.layers.length - 1);
            const selectedFeaturesGraphicsLayer = new GraphicsLayer({ title: LayerIds.SelectedFeatures });
            webmap.add(selectedFeaturesGraphicsLayer);

            mapview = new MapView({
                container: aoiMapDiv.current == null ? undefined : aoiMapDiv.current,
                map: webmap,
                ui: { components: ["attribution"] },
            });

            const compass = new Compass({
                view: mapview
            });

            mapview.ui.add(compass, 'top-right');

            zoomRef.current = new Zoom({
                view: mapview,
                visible: false
            });
            mapview.ui.add(zoomRef.current, 'top-right');

            // Disable map navigation until tabular data is loaded.
            disableMapNavigation(mapview, true);

            mapview.on("layerview-create", function (event) {
                if (event.layer.title === LayerIds.WellsDataGraphics) {
                    wellsLayerView = event.layerView as __esri.FeatureLayerView;
                    wellsLayerView.highlightOptions = {
                        color: new Color("#0000ff"),
                        haloOpacity: 0.9,
                        fillOpacity: 0.2
                    };
                    setWellLayerLoaded(true);
                }
                if (event.layer.title === LayerIds.CountyStats) {
                    countyLayerView = event.layerView as __esri.FeatureLayerView;
                }
            });

            reactiveUtils.watch(
                () => mapview.zoom,
                () => {
                    const countyLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.CountyStats) as FeatureLayer;
                    const countyGraphicsLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.CountyStatsGraphic) as FeatureLayer;
                    if (countyLayer && countyGraphicsLayer) {
                        if (mapview.scale > countyLayer.maxScale) {
                            countyGraphicsLayer.visible = countiesEnabled;
                            countyGraphicsLayer.popupEnabled = true;
                            setShowZoomMessage(true)
                        } else {
                            countyGraphicsLayer.visible = false;
                            countyLayer.visible = false;
                            countyGraphicsLayer.popupEnabled = false;
                            countyLayer.popupEnabled = false;
                            setShowZoomMessage(false)
                        }
                    }
                }
            );

            reactiveUtils.watch(
                () => mapview.stationary,
                (stationary) => {
                    if (stationary === true && webmap) {
                        const wellsLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellData) as FeatureLayer;
                        const rigsLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.RigsData) as FeatureLayer;
                        if (wellsLayer && mapview.scale <= wellsLayer.minScale && mapview.extent !== null) {
                            if (sketch) sketch.visible = true;
                            if (rigsLayer) {
                                //@ts-ignore 
                                rigsLayer.renderer = Renderers.rigPointsRendererWhite;
                            }
                            const outSpatialReference = new SpatialReference({
                                wkid: 4326
                            });
                            try {
                                const wgs84Extent: Extent = projection.project(mapview.extent, outSpatialReference) as Extent;
                                console.log(wgs84Extent.extent.toJSON());
                                const tiles = [0];
                                const widthSplit = tiles.length / 2;
                                const heightSplit = tiles.length / 4;
                                const xDiff = wgs84Extent.xmax - wgs84Extent.xmin;
                                const yDiff = wgs84Extent.ymax - wgs84Extent.ymin;
                                const smallerExtents = tiles.map((num) => {
                                    const xminAdj = 0;//(xDiff/widthSplit) * (num%widthSplit);
                                    const yminAdj = 0;//(yDiff/heightSplit) * Math.floor(((tiles[tiles.length - num - 1]/widthSplit)))
                                    const xmaxAdj = 0;//(xDiff/widthSplit) * (tiles[tiles.length - num - 1]%widthSplit);
                                    const ymaxAdj = 0;//(yDiff/heightSplit) * Math.floor(num/widthSplit);
                                    return {
                                        tile: num,
                                        spatialReference: { wkid: 4326 },
                                        xmin: wgs84Extent.xmin + xminAdj,
                                        ymin: wgs84Extent.ymin + yminAdj,
                                        xmax: wgs84Extent.xmax - xmaxAdj,
                                        ymax: wgs84Extent.ymax - ymaxAdj
                                    }
                                })
                                setAWSSmallerExtents((prevState) => {
                                    if (inExtent(smallerExtents?.[0], prevState?.[0])) {
                                        return prevState;
                                    }
                                    const e: IAWSEXtent = getBufferedExtent(smallerExtents?.[0]);
                                    //setPrevExtent(prevState?.[0]);
                                    return [e];
                                });
                            } catch (ex) {
                                console.warn('Map error, unable to obtain current wgs84 extent');
                            }
                        } else {
                            if (sketch) sketch.visible = false;
                            if (rigsLayer) {
                                //@ts-ignore 
                                rigsLayer.renderer = Renderers.rigPointsRenderer;
                            }
                        }
                    }
                }
            )

            reactiveUtils.watch(
                () => mapview.popup.visible,
                (visible) => {
                    if (visible) {
                        setPopupOpen((prevState) => {
                            if (prevState !== true) {
                                setTimeout(() => mapview.popup.reposition(), 200)
                                return true;
                            }
                            return prevState;
                        });
                    } else {
                        setPopupOpen((prevState) => {
                            if (prevState === true) {
                                return false;
                            }
                            return prevState;
                        });
                    }
                }
            );
            reactiveUtils.watch(
                () => mapview.popup.selectedFeatureIndex,
                (selectedFeatureIndex) => {
                    setPopupSelectedIndex(selectedFeatureIndex);
                }
            );
            reactiveUtils.watch(
                () => mapview.popup.features,
                (features) => {
                    if (features && features.length > 0) {
                        if (features.length > 1) {
                            mapview.popup.selectedFeatureIndex = 1;
                        }
                    }
                }
            );

            const countyGraphicsLayer = BaseLayers.getCountyGraphicsLayer();
            countyGraphicsLayer.visible = false;
            mapview.map.layers.add(countyGraphicsLayer);

            const wellGraphicsLayer = BaseLayers.getWellsGraphicsLayer([]);
            wellGraphicsLayer.visible = false;
            mapview.map.layers.add(wellGraphicsLayer);

            const wellLinesGraphicsLayer = BaseLayers.getLinesGraphicsLayer([]);
            wellLinesGraphicsLayer.visible = false;
            mapview.map.layers.add(wellLinesGraphicsLayer);

            mapview.on('click', (event: any) => {
                setScreenPoint(event);
                event.preventDefault();
            });

            webmap.when(() => {
                webmap.layers.forEach((layer) => layer.opacity = 0);
                console.log("loaded");
                dispatch(INIT_MAP({ map: webmap, mapview }));
                //console.log('setting legend...');
                //allowCreateAoI signfies that the aoi page has been loaded first...
                if (allowCreateAoI) {
                    const allIds = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.map(v => v.value);
                    dispatch(SET_FILTER([{ layerId: LayerIds.WellData, filterAllIfEmpty: false, value: allIds, field: FieldNames.wellDataPointsTypeId }]))
                }

                const layers = webmap.layers
                    .filter(layer => { return layer.type === 'feature' })
                    .map(layer => {
                        let featLayer: FeatureLayer = layer as FeatureLayer;
                        featLayer.outFields = ['*'];
                        const isPoint = featLayer.title === LayerIds.WellData
                        if (isPoint || featLayer.title === LayerIds.LateralLines) {
                            featLayer.definitionExpression = wellDataQuery
                            if (isPoint) {
                                //@ts-ignore
                                featLayer.renderer = Renderers.wellsByTypeRendererPlatform;
                            }
                            featLayer.visible = false;
                        } else {
                            if (featLayer.title !== LayerIds.WellsDataGraphics) {
                                featLayer.definitionExpression = subscriptionQuery
                            }
                        }
                        if (featLayer.title === LayerIds.RigsData) {
                            //@ts-ignore 
                            featLayer.renderer = Renderers.rigPointsRenderer;
                        }
                        return featLayer;
                    });


                sketch = new Sketch({
                    layer: graphicsLayer,
                    view: mapview,
                    creationMode: "single",
                    availableCreateTools: ["polygon", "circle", "rectangle"],
                    visibleElements: {
                        createTools: { point: false, polyline: false },
                        duplicateButton: false,
                        selectionTools: { "lasso-selection": false, "rectangle-selection": false },
                        settingsMenu: false,
                        undoRedoMenu: false,
                    },
                    visible: false
                });
                sketch.on("create", function (event) {
                    if ((event.state === "start") || (event.state === "cancel")) {
                        graphicsLayer.removeAll();
                        setCurrentConfirmSketch(undefined);
                    }
                    if (event.state === "complete") {
                        //@ts-ignore
                        setCurrentConfirmSketch(event.graphic);
                    }

                })
                sketch.on('delete', function (event) {
                    //AOI Popup
                    mapview.popup.close();
                    setCurrentConfirmSketch(undefined);
                })

                reactiveUtils.watch(
                    () => sketch.visible,
                    () => {
                        const updateSketchStyles = () => {
                            const container = sketch.container;
                            if (typeof container === "string") {
                                setTimeout(() => updateSketchStyles(), 50);
                                return;
                            }
                            const sketchSections = container.querySelectorAll(".esri-sketch__section");
                            if (!sketchSections || sketchSections.length === 0) {
                                setTimeout(() => updateSketchStyles(), 50);
                                return;
                            }

                            sketchSections.forEach(sketchSection => {
                                sketchSection.querySelectorAll("calcite-action").forEach((element: any) => {
                                    element.setAttribute("scale", "m")
                                });
                            });
                        }
                        updateSketchStyles();
                    }
                );

                reactiveUtils.on(() =>
                    mapview.popup,
                    'trigger-action',
                    function (event) {
                        setSaveCurrentType(event.action.id);
                    }
                );
                mapview.ui.add(sketch, 'top-right');

                mapview.popup.defaultPopupTemplateEnabled = false;
                mapview.popup.dockEnabled = true;

                const mapKey = new Expand({
                    view: mapview,
                    content: filterPanelRef.current == null ? undefined : filterPanelRef.current,
                    expanded: true,
                    expandIcon: 'key',
                    expandTooltip: 'Show Map Key',
                    collapseTooltip: 'Hide Map Key',
                });

                reactiveUtils.watch(() =>
                    mapKey.expanded,
                    (expanded) => {
                        setMapKeyVisible(expanded);
                    }
                );
                mapview.ui.add(mapKey, 'top-right');

                setLayersLoaded(true);
            });
        }
    }, [config, subscriptionQuery]);

    useEffect(() => {
        aoiDataLoading &&
            dispatch(
                fetchAoiList(access_token, false)
            );
        // eslint-disable-next-line
    }, [aoiDataLoading]);

    useEffect(() => {
        if (!createAoiModal) {
            dispatch(clearCurrentAoi());
        }
    }, [createAoiModal])

    useEffect(() => {
        if (layersLoaded) {
            const aoiLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.AoIData);
            if (aoiLayer) {
                const sym = { type: 'simple-fill', color: "#16A15E33" };
                aoiLayer.removeAll();
                savedAoiData.forEach((item: AoiListObject) => {
                    const geoJson = wktToGeoJSON(item.geom);
                    const geom: any = geojsonToArcGIS(geoJson)
                    const g = new Graphic({
                        attributes: {
                            well_count: item.well_count,
                            permit_count: item.permit_count,
                            completion_count: item.completion_count,
                            operator_count: item.operator_count,
                            name: item.aoi_name,
                            id: item.id
                        },
                        geometry: { ...geom, type: 'polygon' },
                        symbol: sym
                    })
                    //@ts-ignore
                    //g.popupTemplate = aoiDataTemplate;
                    aoiLayer.graphics.push(g);
                    if (lastAddedAoiName && lastAddedAoiName === item.aoi_name) {
                        mapview.goTo(g.geometry);
                        dispatch(setLastAddedAoiName(""));
                    }
                })
            }
        }
    }, [savedAoiData, layersLoaded]);

    useEffect(() => {
        if (layersLoaded && panelFilters) {
            dispatch(SetPanelFilter({ ...panelFilters, force: true }));
        }
    }, [layersLoaded])

    useEffect(() => {
        if (layersLoaded && selectedAoiData) {
            updateAoi(selectedAoiData.aoi_id);
        }
    }, [selectedAoiData]);

    const updateAoi = (aoi_id: number) => {
        if (aoi_id > 0) {
            const aoiLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.AoIData);
            if (aoiLayer) {
                const aoi = aoiLayer.graphics.find((g: Graphic) => g.getAttribute('id') === selectedAoiData.aoi_id);
                if (aoi) {
                    mapZoomToTarget(aoi);
                    updateAoiSelection(aoi);
                }
            }
        }
    }

    useEffect(() => {
        const noAoI = !selectedAoiData || selectedAoiData.aoi_id == 0;
        const noCurrentWells = !selectedWellRigOrPermitUIDList || selectedWellRigOrPermitUIDList.length < 1;

        if (noAoI && noCurrentWells && !popupOpen) {
            resetEffectedWells();
        }

    }, [selectedAoiData, selectedWellRigOrPermitUIDList, popupOpen]);

    useEffect(() => {
        if (layersLoaded && !!selectedRowId || selectedRowId.toString().length === 0 && popupOpen) {
            mapview.popup.visible = false;
        }
        if (!selectedRowId || (!config || !config.hasOwnProperty("mapConfig"))) {
            return;
        }
        const layer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.RigsData) as FeatureLayer;

        layer.queryFeatures({ where: `rig_id = '${selectedRowId}'`, returnGeometry: true, outFields: ["*"] }).then((res: any) => {
            if (res.features.length > 0) {
                const geom = res.features[0].geometry;
                //@ts-ignore
                const { mapConfig } = config;
                mapZoomToTarget({ target: geom, zoom: mapConfig.highlightZoom });
                mapview.openPopup({ features: res.features, location: res.features[0].geometry, });
            }
        });

    }, [selectedRowId, config]);

    useEffect(() => {
        if ((!downloadCol && showTableLoader) || !countiesLoaded || graphicLayersLoading) {
            setMapLoading(true);
        }
        if (mapLoading && !downloadCol && !showTableLoader && countiesLoaded && !graphicLayersLoading) {
            setMapLoading(false);
        }
    }, [showTableLoader, countiesLoaded, graphicLayersLoading])

    useEffect(() => {
        const updatePopupStyles = () => {
            const container = mapview.popup.container;
            if (typeof container === "string") {
                setTimeout(() => updatePopupStyles(), 50);
                return;
            }

            const contentFeatureElement = container.querySelector(
                ".esri-features__content-feature"
            );

            if (!contentFeatureElement) {
                setTimeout(() => updatePopupStyles(), 50);
                return;
            }

            //The action bar (immediately beneath the title bar)
            const calciteActionBar =
                contentFeatureElement.querySelector("calcite-action-bar");

            if (!calciteActionBar) {
                setTimeout(() => updatePopupStyles(), 50);
                return;
            }

            //The individual buttons in the action bar
            const calciteActionElements =
                calciteActionBar.querySelectorAll("calcite-action");

            if (calciteActionElements.length === 0) {
                setTimeout(() => updatePopupStyles(), 50);
                return;
            }

            for (let x = 0; x < calciteActionElements.length; x++) {
                const element =
                    calciteActionElements[x].shadowRoot?.querySelector(
                        "button"
                    );

                if (!element) {
                    setTimeout(() => updatePopupStyles(), 50);
                    return;
                }

                calciteActionElements[x].setAttribute("text-enabled", "");

                element.style.padding = "2px 10px";
                element.style.marginBottom = "5px";

                const [iconContainer] = element.querySelectorAll(
                    ".icon-container"
                ) as NodeListOf<HTMLElement>;
                iconContainer.style.height = "30px";
                iconContainer.style.width = "30px";

                const calciteIcons = element.querySelectorAll(
                    "calcite-icon"
                ) as NodeListOf<HTMLElement>;
                const featureIcons = contentFeatureElement.querySelectorAll(
                    "span.esri-features__icon"
                ) as NodeListOf<HTMLElement>;
                const icons = [...calciteIcons, ...featureIcons];
                icons.forEach((icon) => {
                    icon.style.height = "90%";
                    icon.style.width = "90%";
                    icon.style.fontSize = "25px";
                });
                const [textContainer] = element.querySelectorAll(
                    ".text-container"
                ) as NodeListOf<HTMLElement>;
                textContainer.style.fontSize = "15.5px";
                textContainer.style.marginLeft = "4px";
            }
            setTimeout(() => { mapview.popup.reposition() }, 200);
        };
        if (popupOpen) {
            updatePopupStyles();
            window.addEventListener("resize", updatePopupStyles);
        } else {
            window.removeEventListener("resize", updatePopupStyles);
        }
        return () => {
            window.removeEventListener("resize", updatePopupStyles);
        };
    }, [popupOpen]);

    useEffect(() => {
        if (!mapLoading && layersLoaded) {
            //Refresh selected AOI layer effects
            if (selectedAoiData && selectedAoiData.aoi_id !== 0) {
                console.log("Adding aoi layer effect");
                const aoiLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.AoIData);
                if (aoiLayer) {
                    const aoi = aoiLayer.graphics.find((g: Graphic) => g.getAttribute('id') === selectedAoiData.aoi_id);
                    if (aoi) {
                        updateAoiSelection(aoi);
                        addLayerEffectForSelectedAoi(aoi);
                    }
                }
            }
            // Re enable map navigation
            disableMapNavigation(mapview, false);

            // Restore opacity of all webmap layers to 1
            webmap.layers.forEach((layer) => layer.opacity = 1)

            if (zoomRef.current) {
                zoomRef.current.visible = true;
            }
        } else {
            if (mapview) {
                // Disable map navigation until tabular data is loaded.
                disableMapNavigation(mapview, true);
                if (zoomRef.current) {
                    zoomRef.current.visible = false;
                }
            }
        }
        if (mapLoading) {
            if (!mapHasLoadedOnce) {
                setMapHasLoadedOnce(true);
            }
        }
    }, [mapLoading])

    useEffect(() => {

        if (popupOpen) {
            //ENE-500 remove popup
            // const tmpFeature = mapview.popup.features && mapview.popup.features.length > 0
            //     ? mapview.popup.features[mapview.popup.selectedFeatureIndex].getAttribute('uid')
            //     : "";
            const tmpFeature = selectedWellRigOrPermitUIDList && selectedWellRigOrPermitUIDList.length > 0
                ? selectedWellRigOrPermitUIDList[0]
                : "";
            // !featuresUpdated || 
            const featureSelected = selectedRowId && selectedRowId.toString().length > 0 && (tmpFeature === selectedRowId)
            const currentFeature = featureSelected
                ? selectedRowId
                : tmpFeature
            let aoi: any;
            const aoiIndex = mapview.popup.features.findIndex((f) => (f.layer && f.layer.title === LayerIds.AoIData) || (f.attributes && f.attributes.title === 'Area of Interest Statistics'))
            if (aoiIndex > -1) {
                aoi = mapview.popup.features[aoiIndex];
            }

            //Single feature selected
            // if a single feature is selected, then clear the selected AOI
            if (selectedAoiData) {
                dispatch(handleSelectedAoiData({ aoi_id: 0 }))
            }
            if (currentFeature && currentFeature !== "") {
                if (!featureSelected && currentFeature !== selectedWellRigOrPermitUIDList[0]) {
                    setCheckandSetFeatures([currentFeature.toString()]);
                }
                if (aoiIndex > -1 && selectedWellRigOrPermitUIDList.length !== 1) {
                    addLayerEffectForSelectedAoi(aoi);
                } else {
                    addLayerEffectForSelectedFeatures([currentFeature.toString()]);
                }
            } else {
                if (aoi) {
                    updateAoiSelection(aoi);
                }
            }
            setTimeout(() => { mapview.popup.reposition() }, 500);
        } else {
            if (currentConfirmSketch) {
                const gLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.AoiSketch) as GraphicsLayer;
                if (gLayer) {
                    gLayer.removeAll();
                }
            }
            setCurrentConfirmSketch(undefined);
        }
    }, [popupOpen, popupSelectedIndex]);

    useEffect(() => {
        //Check for updated filter properties...
        if (panelFilters && panelFilters.hasOwnProperty(FieldNames.queryPanelKeys[0])) {
            const renderers = getCurrentRenderers(panelFilters);
            const filterAllIfEmpty = false;
            if (layers) {
                const filters = []
                filters.push({ layerId: LayerIds.WellData, filterAllIfEmpty, value: renderers, field: FieldNames.wellDataPointsTypeId });

                FieldNames.queryPanelKeys.forEach((panelKey, i) => {
                    if (panelKey in panelFilters) {
                        let formattedList: string[] = [];
                        if (Array.isArray(panelFilters[panelKey])) {
                            formattedList = panelFilters[panelKey].map((filterValue: string) => {
                                const idxs = filterValue.split("");
                                return idxs.map((c: string, i) => {
                                    return i == 0
                                        ? c.toUpperCase()
                                        : [' ', '-', '_', '/'].includes(idxs[i - 1])
                                            ? c.toUpperCase()
                                            : c
                                }).join("");
                                //return filterValue.charAt(0).toUpperCase() + filterValue.slice(1);

                            });
                        } else {
                            for (const key in panelFilters[panelKey]) {
                                if (key.toLowerCase() === "start") {
                                    formattedList.push("DATERANGE");
                                }
                                formattedList.push(panelFilters[panelKey][key]);
                            }
                            for (const key in panelFilters[panelKey]) {
                                if (key.toLowerCase() === "min") {
                                    formattedList.push("NUMBERRANGE");
                                }
                                formattedList.push(panelFilters[panelKey][key]);
                            }
                        }
                        filters.push({ layerId: LayerIds.WellData, filterAllIfEmpty, value: formattedList, field: FieldNames.panelKeyMatch[i] })
                    } else {
                        filters.push({ layerId: LayerIds.WellData, filterAllIfEmpty, value: [], field: FieldNames.panelKeyMatch[i] });
                    }
                });
                dispatch(SET_FILTER(filters));
            }
        }
    }, [panelFilters])

    useEffect(() => {
        if (layersLoaded && mapLineData) {
            const mapWellGraphics: Graphic[] = [];
            mapData.forEach((mapDataTile, idx) => {
                if (mapDataTile.data !== null) {
                    mapDataTile.data.forEach((f) => {
                        const pt = {
                            type: 'point',
                            spatialReference: { wkid: 4326 },
                            y: f.latitude,
                            x: f.longitude
                        }
                        mapWellGraphics.push(new Graphic({ attributes: { ...f, ObjectId: idx + 1 }, geometry: pt }));
                    });
                }
            });
            const mapWellLineGraphics: Graphic[] = [];
            mapLineData.forEach((mapLineDataTile, idx) => {
                if (mapLineDataTile.data !== null) {
                    mapLineDataTile.data.forEach((f) => {
                        if (mapData !== null && mapData.length > 0 && mapData[0].data?.find((md) => md.well_api === f.well_api)) {
                            const line = wktToGeoJSON(f.line_geometry);
                            const esriLine: any = geojsonToArcGIS(line);
                            mapWellLineGraphics.push(new Graphic({ attributes: { ...f, ObjectId: idx + 1 }, geometry: { ...esriLine, type: 'polyline' } }));
                        }
                    });
                }
            });
            updateWellGraphics(mapWellGraphics, mapWellLineGraphics);
        }
    }, [mapData, layersLoaded])

    useEffect(() => {
        if (!awsSmallerExtents || awsSmallerExtents.length === 0) {
            return
        }
        setGraphicLayersLoading(true);
        //abort graphic layers loading if they haven't loaded in 10 seconds...
        setTimeout(() => {
            setGraphicLayersLoading(false);
        }, 10000);
        const handlerAbort = new AbortController();
        const e = awsSmallerExtents[0];
        const poly = `POLYGON((${e.xmin} ${e.ymin}, ${e.xmin} ${e.ymax}, ${e.xmax} ${e.ymax}, ${e.xmax} ${e.ymin}, ${e.xmin} ${e.ymin}))`;
        dispatch(getMapData(access_token, handlerAbort, ({ ...e, ...panelFilters, geometry: poly } as unknown) as any));//, mapData[e.tile].controller));

        return () => {
            handlerAbort.abort();
        }
    }, [panelFilters, awsSmallerExtents])

    useEffect(() => {
        if (!layersLoaded) {
            return;
        }
        if (!layers || !layers[LayerIds.WellData] || !layers[LayerIds.WellData][FieldNames.wellDataPointsTypeId]) {
            return;
        }
        const formData: any = {}
        const renderers = getCurrentRenderers(panelFilters);
        if ([...layers[LayerIds.WellData][FieldNames.wellDataPointsTypeId]].sort().join(',') === renderers.sort().join(',')) {
            return;
        }
        const labelsSelected = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.filter((info) => {
            return layers[LayerIds.WellData][FieldNames.wellDataPointsTypeId].indexOf(info.value) > -1
        }).map((info) => info.label);
        const wellTypes = wellTypeOption.filter((opt) => labelsSelected.includes(opt.value))
        if (wellTypes) {
            formData['well_type'] = wellTypes.map((wt) => wt.value);
        }
        const wellStatus = wellStatusOption.filter((opt) => labelsSelected.includes(opt.value))
        if (wellStatus) {
            formData['well_status'] = wellStatus.map((wt) => wt.value);
        }
        const drillType = drillTypeOption.filter((opt) => labelsSelected.includes(opt.value))
        if (drillType) {
            formData['drill_type'] = drillType.map((wt) => wt.value);
        }
        const prod_type = productType.filter((opt) => labelsSelected.includes(opt.value))
        if (prod_type) {
            formData['production_type'] = prod_type.map((wt) => wt.value);
        }
        //('changing left panel..');
        dispatch(SetPanelFilter(formData));
    }, [layers]);

    useEffect(() => {
        if (access_token && loadSubscriptionData) {
            dispatch(fetchSubscriptionData(access_token));
        }
    }, [access_token, loadSubscriptionData])

    useEffect(() => {
        if (countyLayerView) {
            switch (selectedType) {
                case BASINS:
                    mapview.layerViews.forEach((lv: { layer: { title: string; }; visible: boolean; }) => {
                        lv.layer.title === LayerIds.BasinStats ? lv.visible = true : lv.visible = false;
                    })
                    break;
                case COUNTIES:
                    mapview.layerViews.forEach((lv: { layer: { title: string; }; visible: boolean; }) => {
                        lv.layer.title === LayerIds.CountyStats ? lv.visible = true : lv.visible = false;
                    })
                    break;
            }
        }
    }, [selectedType]);

    useEffect(() => {
        if (currentConfirmSketch) {
            const poly = currentConfirmSketch.geometry as Polygon
            addLayerEffectForSelectedAoi(currentConfirmSketch);

            //query for features
            const wLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellsDataGraphics);
            const lLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellLineGraphics);
            //const rLayer:any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.RigsData);
            //const lLayer:any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.LateralLines);
            //if (!wLayer || !wLayer.hasOwnProperty(queryFeatures)) return;
            const query = {
                //query object
                geometry: currentConfirmSketch.geometry,
                spatialRelationship: "intersects",
                returnGeometry: false,
                outFields: ["well_api", "uid"],
            }
            dispatch(showSiteLoader());
            queryForUniqueWellIds(query, true).then((wellIds: any) => {
                setCheckandSetFeatures(wellIds);
                //@ts-ignore
                currentConfirmSketch.popupTemplate = confirmDataTemplate;
                let outSpatialReference = new SpatialReference({
                    wkid: 4326 //Sphere_Sinusoidal projection
                });
                const tmpWGS84 = projection.project(currentConfirmSketch.geometry, outSpatialReference) as __esri.Geometry;
                const geoJson = arcgisToGeoJSON(tmpWGS84)
                const wkt = geojsonToWKT(geoJson)
                dispatch(
                    fetchAoiStats(access_token, { ...panelFilters, geometry: wkt })
                ).then((result: any) => {
                    if (result.msg == 'success') {
                        currentConfirmSketch.attributes = { ...result.data }
                        currentConfirmSketch.attributes.title = "Area of Interest Statistics"
                    } else {
                        currentConfirmSketch.attributes = {}
                        currentConfirmSketch.attributes.title = "AOI contains no data returned"
                    }
                    mapview.popup.location = poly.centroid;
                    mapview.openPopup({ features: [currentConfirmSketch], location: poly.centroid });
                })
                    .finally(() => {
                        dispatch(hideSiteLoader());
                    });
            })
                .catch((err) => {
                    console.log('the error with querying for unique well ids is ', err)
                });
        } else {
            setCheckandSetFeatures([]);
        }
    }, [currentConfirmSketch])

    useEffect(() => {
        if (!subscriptionData) return;
        let tmpSubs: ISubscriptionMapData[] = [];
        const { details } = subscriptionData;
        details.forEach((item) => {
            tmpSubs.push({ type: item.line_item_type, name: item.line_item_name, state: item.state_code });
        });
        setCurrentSubscriptions(tmpSubs);
    }, [subscriptionData])

    useEffect(() => {
        if (currentConfirmSketch) {
            let outSpatialReference = new SpatialReference({
                wkid: 4326 //Sphere_Sinusoidal projection
            });
            const tmpWGS84 = projection.project(currentConfirmSketch.geometry, outSpatialReference) as __esri.Geometry;
            if (saveCurrentType === 'aoi') {
                if (currentConfirmSketch) {
                    const geoJson = arcgisToGeoJSON(tmpWGS84.toJSON())
                    dispatch(NEW_AOI({ geojson: geoJson, crs: tmpWGS84.spatialReference.wkid }));
                    setSaveCurrentType('');
                    mapview.popup.close();
                }
            }
            if (saveCurrentType === 'projects') {
                if (currentConfirmSketch) {
                    const geoJson = arcgisToGeoJSON(tmpWGS84.toJSON())
                    dispatch(NEW_PROJECT({ geojson: geoJson, crs: tmpWGS84.spatialReference.wkid }));
                    setSaveCurrentType('');
                    mapview.popup.close();
                }
            }
        }
    }, [saveCurrentType])

    useEffect(() => {
        if (screenPoint !== prevScreenPoint.current && mapSet)
            handleMapClick(screenPoint, panelFilters, selectedWellRigOrPermitUIDList);
        prevScreenPoint.current = screenPoint;
    }, [screenPoint]);

    useEffect(() => {
        if (!layersLoaded) {
            return;
        }
        if (selectedWellRigOrPermitUIDList && selectedWellRigOrPermitUIDList.length >= 1) {
            addLayerEffectForSelectedFeatures(selectedWellRigOrPermitUIDList);
        } else {
            resetEffectedWells();
        }
    }, [selectedWellRigOrPermitUIDList, layersLoaded]);

    useEffect(() => {
        if (wellLayerLoaded && selectedRowId && selectedRowId.toString().length > 0) {
            selectFeatures(selectedRowId.toString(), true, true);
        } else {
            if (selectedHighlight !== null) {
                selectedHighlight.remove();
                mapview.closePopup();
                setSelectedHighlight(null);
            }
        }
    }, [selectedRowId, wellLayerLoaded]);

    useEffect(() => {
        if (!loadSubscriptionData) {
            let query = defaultQuery;
            if (currentSubscriptions && currentSubscriptions.length > 0) {

                const national = currentSubscriptions.filter((cs) => cs.type === 3);
                if (national && national.length > 0) {
                    query = "1=1";
                } else {
                    const basins = currentSubscriptions.filter((cs) => cs.type === 1);
                    const basinsClause = basins.length > 0 ? (basins.map((ab: ISubscriptionMapData) =>
                        FieldNames.countyStatsBasinName + " = '" + ab.name + "'")).join(" OR ") : "1=0";

                    const counties = currentSubscriptions.filter((cs) => cs.type === 2);
                    const countyClause = counties.length > 0
                        ? (counties.map((ab: ISubscriptionMapData) =>
                            "(" + FieldNames.countyStatsBasinName + " = '" + ab.name + "' AND " +
                            FieldNames.countyStatsStateAbbr + " = '" + ab.state + "')")).join(" OR ")
                        : "1=0";


                    query = "(" + countyClause + ") OR (" + basinsClause + ")";
                }
            }
            setSubscriptionQuery(query)
            const reg1 = new RegExp(FieldNames.countyStatsBasinName, "g")
            const reg2 = new RegExp(FieldNames.countyStatsStateAbbr, "g")
            const reg3 = new RegExp(FieldNames.countyStatsCountyName, "g")
            if (query === defaultQuery) {
                setWellDataQuery("1=0");
            } else {
                setWellDataQuery(query.replace(reg1, FieldNames.wellPointsBasin)
                    .replace(reg2, FieldNames.wellPointsStateAbbr)
                    .replace(reg3, FieldNames.wellPointsCounty))
            }
        }
    }, [currentSubscriptions, loadSubscriptionData]);

    useEffect(() => {
        if (layersLoaded) {
            webmap.layers
                .filter(layer => { return layer.type === 'feature' })
                .map(layer => {
                    let featLayer: FeatureLayer = layer as FeatureLayer;
                    const isPoint = featLayer.title === LayerIds.WellData
                    if (isPoint || featLayer.title === LayerIds.LateralLines) {
                        featLayer.definitionExpression = wellDataQuery
                        if (isPoint) {
                            //@ts-ignore
                            featLayer.renderer = Renderers.wellsByTypeRendererPlatform
                            const pLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellsDataGraphics) as FeatureLayer;
                            pLayer.renderer = featLayer.renderer;
                            pLayer.minScale = featLayer.minScale;
                            pLayer.maxScale = featLayer.maxScale;
                            //ENE-500 remove popup
                            // pLayer.popupTemplate = featLayer.popupTemplate;
                            // pLayer.popupEnabled = true;
                            featLayer.visible = false;
                        } else {
                            const lLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellLineGraphics) as FeatureLayer;
                            lLayer.renderer = featLayer.renderer;
                            lLayer.minScale = featLayer.minScale;
                            lLayer.maxScale = featLayer.maxScale;
                            //ENE-500 remove popup
                            // lLayer.popupTemplate = featLayer.popupTemplate;
                            // lLayer.popupEnabled = true;
                        }
                    } else {
                        if (featLayer.title !== LayerIds.WellsDataGraphics) {
                            featLayer.definitionExpression = subscriptionQuery
                        }
                        if (featLayer.title == LayerIds.CountyStats) {
                            const wLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.CountyStatsGraphic) as FeatureLayer;
                            wLayer.renderer = featLayer.renderer;
                            wLayer.popupTemplate = featLayer.popupTemplate;
                            wLayer.popupEnabled = true;

                            featLayer.queryFeatureCount().then((count: number) => {
                                const queries = [];
                                const numQueries = Math.ceil(count / 2000);
                                for (let x = 0; x < numQueries; x++) {
                                    queries.push(featLayer.queryFeatures({ where: featLayer.definitionExpression, outFields: ["*"], start: x * 2000, num: 2000, returnGeometry: true }))
                                }
                                let allFeatures: any[] = [];
                                Promise.all(queries)
                                    .then((results) => {
                                        results.forEach((countiesFeatureSet: { features: any[]; }) => {
                                            allFeatures = [...allFeatures, ...countiesFeatureSet.features];
                                        });
                                        updateCountyGraphics(allFeatures);
                                    });
                            })
                        }
                    }
                    if (featLayer.title === LayerIds.RigsData) {
                        //@ts-ignore
                        featLayer.renderer = Renderers.rigPointsRenderer;
                    }
                    return featLayer;
                });
        }
    }, [layersLoaded, subscriptionQuery]);

    useEffect(() => {
        if (countyTotals && layersLoaded && countiesLoaded) {
            if (countyTotals.length > 0) {
                if (countyLayerView) {
                    countyLayerView.filter = new FeatureFilter({
                        where: "1=0"
                    });
                }
                const cBaseLayer = webmap.layers.find(layer => { return layer.title === LayerIds.CountyStats }) as FeatureLayer;
                const cLayer = webmap.layers.find(layer => { return layer.title === LayerIds.CountyStatsGraphic }) as FeatureLayer;
                if (cLayer) {
                    setMapLoading(true);
                    cLayer.renderer = cBaseLayer.renderer;
                    cLayer.queryFeatures().then((featureSet) => {
                        const features = featureSet.features;
                        const updates: Graphic[] = [];
                        let singleCountyGraphic: Graphic;
                        let countiesWithResults = 0;
                        features.forEach((f: Graphic) => {
                            const ct = countyTotals.find((ct: CountyStatObject) => {
                                if (!ct.county || ct.county == null || !ct.state_abbr || ct.state_abbr == null)
                                    return false;
                                return ct.county.toLowerCase() === f.getAttribute(FieldNames.countyStatsCountyName).toLowerCase() &&
                                    ct.state_abbr.toLowerCase() === f.getAttribute(FieldNames.countyStatsStateAbbr).toLowerCase();
                            })
                            if (ct) {
                                const jsonFeature = f.toJSON();
                                const g: Graphic = new Graphic({
                                    attributes: jsonFeature.attributes,
                                    geometry: { ...jsonFeature.geometry, type: 'polygon' }
                                });
                                g.attributes[FieldNames.countyWellTotals] = ct.total_wells;
                                if (ct.total_wells > 0) {
                                    countiesWithResults++;
                                    if (!singleCountyGraphic) {
                                        singleCountyGraphic = g;
                                    }
                                }
                                updates.push(g);
                            } else {
                                const jsonFeature = f.toJSON();
                                const g: Graphic = new Graphic({
                                    attributes: jsonFeature.attributes,
                                    geometry: { ...jsonFeature.geometry, type: 'polygon' }
                                });
                                g.attributes[FieldNames.countyWellTotals] = 0;
                                updates.push(g);
                            }
                        });
                        if (updates.length > 0) {
                            cLayer.applyEdits({ updateFeatures: updates }).then((results) => {
                                if (!popupOpen && (!selectedAoiData || selectedAoiData.aoi_id === 0)) {
                                    if (countiesWithResults === 1) {
                                        setTimeout(() => {
                                            console.log('setting zoom to wells');
                                            setZoomToWells(true);
                                        }, 500);
                                    } else {
                                        zoomToCounties();
                                    }
                                } else {
                                    if (popupOpen) {
                                        setPopupOpen(false);
                                        mapview.popup.close();
                                        zoomToCounties();
                                    }
                                }
                            });
                        } else {
                            setMapLoading((prevState) => {
                                if (!countiesLoaded || showTableLoader) return prevState;
                                return false;
                            });
                        }

                    });
                }
            }
            const countyGraphicsLayerView = mapview.allLayerViews.find((lv) => lv.layer.title === LayerIds.CountyStatsGraphic);
            if (countyGraphicsLayerView) {
                countyGraphicsLayerView.visible = countiesEnabled;
            }
        }
    }, [countyTotals, layersLoaded, countiesLoaded]);

    useEffect(() => {
        if (wellsDataList !== null && zoomToWells && !showTableLoader && config.hasOwnProperty("mapConfig") &&
            (!selectedRowId || selectedRowId.toString().length === 0) && selectedWellRigOrPermitUIDList.length === 0) {
            setZoomToWells(false);
            let mp: any = {
                type: 'multipoint',
                spatialReference: { wkid: 4326 }
            };
            wellsDataList.forEach((w) => {
                if (!mp.points) {
                    mp.points = []
                }
                mp.points.push([w.longitude, w.latitude]);
            });
            const mpGeometry = Multipoint.fromJSON(mp);
            if (mpGeometry) {
                console.log("zooming to wells");
                setTimeout(() => {
                    if (mpGeometry.extent && mpGeometry.extent !== null) {
                        if (mpGeometry.extent.height == 0) {
                            //@ts-ignore
                            const { mapConfig } = config;
                            mapZoomToTarget({
                                target: mpGeometry,
                                zoom: mapConfig.highlightZoom
                            });
                        } else {
                            mapZoomToTarget(mpGeometry.extent);
                        }
                        setMapLoading((prevState) => {
                            if (!countiesLoaded || showTableLoader) return prevState;
                            return false;
                        });
                    } else {
                        setMapLoading((prevState) => {
                            if (!countiesLoaded || showTableLoader) return prevState;
                            return false;
                        });
                    }
                }, 500);
            }
        }
    }, [showTableLoader, wellsDataList, zoomToWells]);

    const addLayerEffectForSelectedFeatures = (currentWellIds: string[] | undefined) => {
        // there was a bug where the AOI selection on the map would only add to the checked list and not
        // reset it to just selected wells in the AOI.  This fixes that
        if (tabIndex === 0) {
            if (wellsDataList && JSON.stringify([...new Set(currentWellIds)]) !== JSON.stringify([...new Set(selectedWellRigOrPermitUIDList)])) {
                setCheckandSetFeatures(currentWellIds);
            }
        }

        if (currentWellIds && currentWellIds?.length > 0 && currentWellIds?.length === wellsDataList?.length) {
            return
        }
        if (!getDontGrayOutOtherWells()) {
            setLayerEffectFilter(new FeatureEffect({
                //@ts-ignore
                filter: { where: "uid IN ('" + currentWellIds.join("','") + "')" },
                excludedEffect: excludedEffect
            }), LayerIds.WellsDataGraphics);
            setLayerEffectFilter(new FeatureEffect({
                //@ts-ignore
                filter: { where: "uid IN ('" + currentWellIds.join("','") + "')" },
                excludedEffect: excludedEffect
            }), LayerIds.WellLineGraphics);
        }

        // setLayerEffectFilter(new FeatureEffect({
        //     //@ts-ignore
        //     filter: { where: "uid IN ('" + currentWellIds.join("','") + "')" },
        //     excludedEffect: excludedEffect
        // }), LayerIds.RigsData);
    }

    const addLayerEffectForSelectedAoi = (aoi: Graphic) => {
        setLayerEffectFilter(new FeatureEffect({
            filter: {
                geometry: aoi.geometry,
                spatialRelationship: "intersects"
            },
            excludedEffect: excludedEffect
        }), LayerIds.WellLineGraphics);
        const query =
        {
            //query object
            geometry: aoi.geometry,
            spatialRelationship: "intersects",
            returnGeometry: false,
            outFields: ["uid"]
        }
        console.log('adding well ids for aoi selection');
        queryForUniqueWellIds(query).then((wellIds) => {
            setLayerEffectFilter(new FeatureEffect({
                filter: {
                    where: wellIds.length > 0 ? `uid in ('${wellIds.join("','")}')` : "",
                },
                excludedEffect: excludedEffect
            }), LayerIds.WellsDataGraphics);
            // setLayerEffectFilter(new FeatureEffect({
            //     filter: {
            //         geometry: aoi.geometry,
            //         spatialRelationship: "intersects"
            //     },
            //     excludedEffect: excludedEffect
            // }), LayerIds.RigsData);
        });
    }

    useEffect(() => {
        if (checkAndSetFeatures) {
            if (JSON.stringify(checkAndSetFeatures) !== JSON.stringify(selectedWellRigOrPermitUIDList)) {
                dispatch(handleSelectedWellRigOrPermitUIDList(checkAndSetFeatures));
                // we run handle rearrange to move the map-clicked wells to the top of the table
                dispatch(handleRearrange())
            }
            setCheckandSetFeatures(undefined);
        }
    }, [checkAndSetFeatures])

    // const checkAndSetFeatures = (currentWellIds: string[], currentFeatures: string[]) => {
    //     const updatedFeatures = [...currentFeatures];
    //     if (JSON.stringify(updatedFeatures) !== JSON.stringify(currentWellIds)) {
    //         dispatch(handleSelectedWellRigOrPermitUIDList(currentWellIds));
    //         // we run handle rearrange to move the map-clicked wells to the top of the table
    //         dispatch(handleRearrange())
    //     }

    // }

    const getBufferedExtent = (extent: IAWSEXtent) => {
        return {
            tile: extent.tile,
            xmin: extent.xmin - 0.1,
            ymin: extent.ymin - 0.1,
            xmax: extent.xmax + 0.1,
            ymax: extent.ymax + 0.1,
            spatialReference: { wkid: extent.spatialReference.wkid }
        }
    }

    const getCurrentRenderers = (values: any) => {
        const allFilterValues = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos

        const prodType = allFilterValues.filter((opt) => values['production_type'] &&
            values['production_type'].find((v: string) => v.toLowerCase() == opt.label.toLowerCase())).map((opt) => opt.value);

        const drillType = allFilterValues.filter((opt) => values['drill_type'] &&
            values['drill_type'].find((v: string) => v.toLowerCase().indexOf(opt.label.toLowerCase()) > -1)).map((opt) => opt.value);

        const wellTypes = allFilterValues.filter((opt) => values['well_type'] &&
            values['well_type'].find((v: string) => v.toLowerCase().indexOf(opt.label.toLowerCase()) > -1)).map((opt) => opt.value);

        if (values['well_type'] && values['well_type'].includes('production')) {
            if (prodType.includes(1)) wellTypes.push(1)
            if (prodType.includes(2)) wellTypes.push(2)
            if (prodType.includes(3)) wellTypes.push(3)
        }

        const wellStatus = allFilterValues.filter((opt) => values['well_status'] &&
            values['well_status'].find((v: string) => {
                if (v.toLowerCase() === opt.label.toLowerCase())
                    return true;
                else {
                    if (opt.label.toLowerCase().indexOf(v.toLowerCase()) > -1) {
                        const remain = opt.label.toLowerCase().replace(v.toLowerCase(), "").trim();
                        return (values['production_type'] && values['production_type'].find((p: string) =>
                            p.toLowerCase() == remain.toLowerCase().replace("/", "_")))
                    }
                    if ((v.toLowerCase()) == 'inactive' && opt.label.toLowerCase().indexOf('shut-in') > -1) {
                        const remain = opt.label.toLowerCase().replace("shut-in", "").trim();
                        return (values['production_type'] && values['production_type'].find((p: string) =>
                            p.toLowerCase() == remain.toLowerCase().replace("/", "_")))
                    }
                    return false;
                }
            })
        ).map((opt) => opt.value);
        const allTypes = [...wellTypes, ...drillType, ...wellStatus];
        if (allTypes.length > 0) {
            return [...allTypes, -100];
        }
        return Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.map(v => v.value);
    }

    const handleMapClick: any = (event: { preventDefault: () => void; }, panelFilters: IFilter, selectedWellRigOrPermitUIDList: any) => {
        const wlayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellsDataGraphics) as FeatureLayer;
        const layerToQuery = mapview.scale > wlayer.minScale ? LayerIds.CountyStatsGraphic : LayerIds.WellsDataGraphics;
        const layer = webmap.layers.find((l: { title: string; }) => l.title === layerToQuery);
        queryFeatures(layer, event, "", [...highlights], panelFilters, selectedWellRigOrPermitUIDList);
        event.preventDefault();
    }

    const inExtent = (extent: IAWSEXtent, overallExtent: any): boolean => {
        return !!extent && !!overallExtent && (extent.xmin >= overallExtent.xmin &&
            extent.ymin >= overallExtent.ymin &&
            extent.xmax <= overallExtent.xmax &&
            extent.ymax <= overallExtent.ymax);
    }

    const mapZoomToTarget = (target: any, attempt?: number | undefined) => {
        try {
            mapview.goTo(target);
        }
        catch (ex: any) {
            if (!attempt) {
                setTimeout(() => mapZoomToTarget(target, 1), 500);
            }
        }
    }

    const queryFeatures = (layer: any,
        screenPoint: any,
        clause: string = "",
        tmpHighlights: IHighlight[],
        panelFilters: IFilter,
        selectedWellRigOrPermitUIDList: any
    ) => {
        if (!layer || layer.type != 'feature') return;
        const clauseQuery = clause.length > 0;
        let distance = 100;
        if (mapview.scale < 75000) {
            distance = 50;
        }
        if (mapview.scale < 40000) {
            distance = 15;
        }
        if (mapview.scale < 10000) {
            distance = 5;
        }
        const point = screenPoint !== null ? mapview.toMap(screenPoint) : null;
        const query = (!clauseQuery) ?
            {
                //query object
                geometry: point,
                spatialRelationship: "intersects",
                returnGeometry: false,
                outFields: ["*"],
                distance: distance,
                units: "esriSRUnit_Meter"
            } :
            {
                //query object
                where: clause,
                returnGeometry: false,
                outFields: ["*"]
            }
        const queries = [layer.queryFeatures(query)];
        if (layer.title === LayerIds.WellsDataGraphics) {
            const lLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellLineGraphics);
            queries.push(lLayer.queryFeatures(query));
        }

        Promise.all(queries)
            .then((results) => {
                let allFeatures: any[] = [];
                results.forEach((featureSet: { features: any[]; }) => {
                    allFeatures = [...allFeatures, ...featureSet.features]
                });

                if (allFeatures.length === 0) {
                    setCheckandSetFeatures([]);
                    const aoiLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.AoIData);
                    let foundAoi: boolean = false;
                    if (aoiLayer && point != null) {
                        let outSpatialReference = new SpatialReference({
                            wkid: 4326 //Sphere_Sinusoidal projection
                        });
                        const tmpPointWGS84 = projection.project(point, outSpatialReference) as __esri.Geometry;
                        aoiLayer.graphics.forEach((aoi: Graphic) => {
                            const graphicInPoint = geometryEngine.within(tmpPointWGS84, aoi.geometry);
                            if (graphicInPoint) {
                                foundAoi = true;
                                const geoJson = arcgisToGeoJSON(aoi.geometry)
                                const wkt = geojsonToWKT(geoJson)
                                dispatch(
                                    fetchAoiStats(access_token, { ...panelFilters, geometry: wkt })
                                ).then((result: any) => {
                                    const tempAoi = new Graphic({
                                        attributes: { name: aoi.attributes.name },
                                        geometry: aoi.geometry,
                                        popupTemplate: aoiDataTemplate
                                    });
                                    tempAoi.attributes = { ...tempAoi.attributes, ...result.data };
                                    mapview.openPopup({ features: [tempAoi] });
                                    updateAoiSelection(aoi);
                                    addLayerEffectForSelectedAoi(aoi);
                                    setTimeout(() => { mapview.popup.reposition() }, 500);
                                });
                            }
                            if (!foundAoi) {
                                setCheckandSetFeatures([])
                            }
                        });
                    }
                    return;
                }
                //ENE-500 Remove popup
                if ([LayerIds.WellsDataGraphics, LayerIds.WellLineGraphics].includes(layer.title)) {
                    const wellIds: any = allFeatures.map((f) => { return f.attributes.uid; });
                    setCheckandSetFeatures(wellIds);
                } else {
                    if ([LayerIds.CountyStatsGraphic].includes(layer.title) && point !== null) {
                        mapview.openPopup({ features: allFeatures, location: point });
                    }
                }
            });
    }

    const queryForUniqueWellIds = (query: any, filterUniqueWellAPI: boolean = false): Promise<string[]> => {
        if (query.outFields && !query.outFields.includes(["*"])) {
            query.outFields = [...new Set([...query.outFields, "uid", "well_api"])];
        }
        const wellIdsPromise: Promise<string[]> = new Promise((resolve) => {
            const lLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellLineGraphics);
            const wLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellsDataGraphics);
            Promise.all([lLayer.queryFeatures(query), wLayer.queryFeatures(query)])
                .then((results) => {
                    const wellInfo: { keys: string[], data: any } = { keys: [], data: {} };
                    results.forEach((featureSet) => {
                        featureSet.features.forEach((f: { attributes: any }, i: number) => {
                            let well_api = f.attributes.Well_API
                            if (wellInfo.keys.includes(well_api) && !filterUniqueWellAPI) {
                                well_api = well_api + i
                            }
                            wellInfo.keys.push(well_api);
                            wellInfo.data[well_api] = f.attributes.uid;
                        })
                    });
                    const wellIds: string[] = [...new Set([...wellInfo.keys])].map((key) => {
                        return wellInfo.data[key];
                    })
                    resolve([...new Set([...wellIds])]);
                });
        });
        return wellIdsPromise;
    }

    const resetEffectedWells = () => {
        if (layersLoaded) {
            setLayerEffectFilter(new FeatureEffect(), LayerIds.WellsDataGraphics);
            setLayerEffectFilter(new FeatureEffect(), LayerIds.WellLineGraphics);
            //setLayerEffectFilter(new FeatureEffect(), LayerIds.RigsData);
        }
    }

    const selectFeatures = (selectedFeature: string, zoom: boolean, showPopup: boolean) => {
        const query =
        {
            //query object
            where: "",
            returnGeometry: true,
            outFields: [FieldNames.wellPointsUID, FieldNames.listingWellPointsTypeId, FieldNames.wellPointsApi],
        }
        const wLayer: any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellData);
        //const rLayer:any = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.RigsData);
        //Well Id is a float, query requires the .0 to work properly as well Id returns as an int from the api
        const wellClause = "uid = '" + selectedRowId + "'";

        query.where = wellClause;
        dispatch(showSiteLoader());
        Promise.all([wLayer.queryFeatures(query)])//,rLayer.queryFeatures(query)])
            .then((results: any) => {
                results.forEach((featureSet: { features: [any] }) => {
                    if (config && config.hasOwnProperty("mapConfig")) {
                        //@ts-ignore
                        const { mapConfig } = config;
                        if (zoom) {
                            mapZoomToTarget({
                                target: featureSet.features[0].geometry,
                                zoom: mapConfig.highlightZoom
                            });
                        }
                        // if (showPopup) {
                        //     //ENE-500 remove popup
                        //     // mapview.openPopup({
                        //     //     location: featureSet.features[0].geometry,
                        //     //     features: featureSet.features
                        //     // })
                        //     //addLayerEffectForSelectedFeatures([featureSet.features[0].getAttribute('uid')]);
                        // }
                    }
                    // if (featureSet.features.length > 0) {
                    //     const h = wellsLayerView.highlight(featureSet.features);
                    //     setSelectedHighlight({ attributes: featureSet.features[0].attributes, remove: h.remove });
                    // }
                    dispatch(hideSiteLoader());
                })
            })
            .catch(() => {
                dispatch(hideSiteLoader());
            });
    }

    const setLayerEffectFilter = (filter: FeatureEffect, layerTitle: string) => {
        if (webmap && webmap.layers) {
            const wLayer: any = webmap.layers.find((l: { title: string; }) => l.title === layerTitle);
            if (wLayer) {
                (wLayer as __esri.FeatureLayer).featureEffect = filter;
            }
        }
    }

    const updateAoiSelection = (aoi: Graphic) => {
        const query = {
            geometry: aoi.geometry,
            spatialRelationship: "intersects",
            returnGeometry: false,
            outFields: ["well_api", "uid"],
        }
        queryForUniqueWellIds(query, true).then((wellIds) => {
            setCheckandSetFeatures(wellIds);
        })
            .finally(() => {
                setMapLoading((prevState) => {
                    if (!countiesLoaded || showTableLoader) return prevState;
                    return false;
                });
            })
    }

    const updateCountyGraphics = (features: Graphic[]) => {
        const updates: Graphic[] = [];
        const adds: Graphic[] = [];
        const wLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.CountyStatsGraphic) as FeatureLayer;
        const cLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.CountyStats) as FeatureLayer;
        if (wLayer) {
            wLayer.maxScale = cLayer.maxScale;
            wLayer.minScale = cLayer.minScale;
            wLayer.queryObjectIds().then((currentGraphicIds: number[]) => {
                features.forEach((graphic: Graphic) => {
                    currentGraphicIds.includes(graphic.getAttribute("OBJECTID"))
                        ? updates.push(graphic)
                        : adds.push(graphic);
                });
                // Esri color ramps - Blue 3
                // #eff3ffff,#bdd7e7ff,#6baed6ff,#3182bdff,#08519cff
                const colors = ["#eff3ffff", "#bdd7e7ff", "#6baed6ff", "#3182bdff", "#08519cff"];
                wLayer.applyEdits({ addFeatures: adds, updateFeatures: updates }).then((result) => {
                    zoomToCounties();
                    setCountiesLoaded(true);
                    //});
                }).catch((ex) => {
                    console.log(ex)
                });
            })
        }
    }

    const updateWellGraphics = (features: Graphic[], lineFeatures: Graphic[]) => {
        const wellPointsLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellData) as FeatureLayer;
        const graphicsLayer: FeatureLayer = BaseLayers.getWellsGraphicsLayer(features) as FeatureLayer;
        if (wellPointsLayer) {
            graphicsLayer.renderer = wellPointsLayer.renderer;
            //ENE-500 remove popup
            // graphicsLayer.popupTemplate = wellPointsLayer.popupTemplate;
            // graphicsLayer.popupEnabled = true;
        }
        const oldGraphicsLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellsDataGraphics) as FeatureLayer;
        if (oldGraphicsLayer) {
            graphicsLayer.featureEffect = oldGraphicsLayer.featureEffect;
            if (selectedHighlight !== null) {
                setSelectedHighlight(null);
            }
            webmap.layers.remove(oldGraphicsLayer);
        }

        const idx = webmap.layers.indexOf(wellPointsLayer);
        webmap.layers.add(graphicsLayer, idx);

        const wellLinesGraphicsLayer = BaseLayers.getLinesGraphicsLayer(lineFeatures);
        const wellLinesLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.LateralLines) as FeatureLayer;
        if (wellLinesLayer) {
            wellLinesGraphicsLayer.renderer = wellLinesLayer.renderer;
            //ENE-500 remove popup
            // wellLinesGraphicsLayer.popupTemplate = wellLinesLayer.popupTemplate;
            // wellLinesGraphicsLayer.popupEnabled = true;
        }
        const oldLineGraphicsLayer: FeatureLayer = webmap.layers.find((l: { title: string; }) => l.title === LayerIds.WellLineGraphics) as FeatureLayer;
        if (oldLineGraphicsLayer)
            if (oldLineGraphicsLayer) {
                wellLinesGraphicsLayer.featureEffect = oldLineGraphicsLayer.featureEffect;
                if (selectedHighlight !== null) {
                    setSelectedHighlight(null);
                }
                webmap.layers.remove(oldLineGraphicsLayer);
            }
        const idx_line = webmap.layers.indexOf(wellLinesLayer);
        webmap.layers.add(wellLinesGraphicsLayer, idx_line);
        console.log('graphics layers updated');
        setGraphicLayersLoading(false);
    }

    const zoomToCounties = () => {
        if (mapview) {
            const zoomLayer = mapview.map.layers.find((l) => l.title === LayerIds.CountyStatsGraphic) as FeatureLayer;
            zoomLayer.queryExtent({ where: `${FieldNames.countyWellTotals} > 0` }).then((result: any) => {
                console.log("zooming to result");
                setTimeout(() => {
                    if (result.extent && result.extent !== null) {
                        const noZoom = result.extent.height > 9;
                        const expand = noZoom ? 1.0 : 1.2;
                        mapZoomToTarget(result.extent.expand(expand));
                        setMapLoading((prevState) => {
                            if (!countiesLoaded || showTableLoader) return prevState;
                            return false;
                        });
                    }
                }, 500);
            })
                .catch(() => setMapLoading((prevState) => {
                    if (!countiesLoaded || showTableLoader) return prevState;
                    return false;
                }))
        } else {
            setMapLoading((prevState) => {
                if (!countiesLoaded || showTableLoader) return prevState;
                return false;
            });
        }
    }

    const inCurrentExtent = (lat: number, lon: number) => {
        if (mapview && mapview.extent) {
            return mapview.extent.xmax >= lon && lon >= mapview.extent.xmin && mapview.extent.ymax >= lat && lat >= mapview.extent.ymin
        }
        return true;
    }


    if (config === null) {
        return <div className={`aoiMapDiv ${showTable ? '' : 'isHide'} `} ref={aoiMapDiv}></div>;
    }

    const saveAsAOI = {
        title: 'Save as AOI',
        id: 'aoi',
        className: 'esri-icon-map-pin',
        scale: 'm',
        style: 'font-size: 16px!important;'
    }

    const saveAsProjects = {
        title: 'Add to Projects',
        id: 'projects',
        className: 'esri-icon-collection'
    }

    const aoiDataTemplate = {
        content: [{ type: "fields" }],
        fieldInfos: [
            {
                fieldName: "well_count",
                visible: true,
                label: "Active Wells",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },
            {
                fieldName: "permit_count",
                visible: true,
                label: "Active Permits",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },
            {
                fieldName: "operator_count",
                visible: true,
                label: "DUCs",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },
            {
                fieldName: "completion_count",
                visible: true,
                label: "Other",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },

        ],
        title: "{name}"
    }

    const confirmDataTemplate = {
        actions: [saveAsAOI],
        content: [{ type: "fields" }],
        fieldInfos: [
            {
                fieldName: "well_count",
                visible: true,
                label: "Active Wells",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },
            {
                fieldName: "permit_count",
                visible: true,
                label: "Active Permits",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },
            {
                fieldName: "operator_count",
                visible: true,
                label: "DUCs",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },
            {
                fieldName: "completion_count",
                visible: true,
                label: "Other",
                format: {
                    places: 0,
                    digitSeparator: true
                }
            },

        ],
        title: "{title}"
    }

    //ENE-500 remove popup
    // const showPopup: any = (r: any) => {
    //     if (r.results.length > 0) {
    //         const graphicHits = r.results?.filter(
    //             (hitResult: any) => hitResult.type === "graphic"
    //         );
    //         if (graphicHits.length === 0) {
    //             mapview.popup.visible = false;
    //             return;
    //         }
    //         const graphic = graphicHits[0].graphic;
    //         //console.log(graphic.attributes.OBJECTID);
    //         if (!mapview.popup.features || mapview.popup.features.length === 0
    //             || mapview.popup.features[0].attributes.OBJECTID !== graphic.attributes.OBJECTID) {
    //             //ENE-500 remove popup
    //             // mapview.openPopup({
    //             //     location: graphic.geometry.centroid ? graphic.geometry.centroid : r.results[0].mapPoint,
    //             //     features: [graphic]
    //             // })
    //         } else {
    //             // mapview.popup.visible = true;
    //         }
    //     } else {
    //         mapview.popup.visible = false;
    //     }
    // }

    const tmpConfig: IBaseMapConfig = config as IBaseMapConfig;
    return (
        <div>
            {mapLoading ? <BouncingDotsLoader /> : <div />}
            <div className={`aoiMapDiv ${showTable ? '' : 'isHide'} `} ref={aoiMapDiv} />
            <div ref={filterPanelRef}>
                {mapKeyVisible && layersLoaded && mapHasLoadedOnce && <FilterPanel useFilter={false} useLegend={true} />}
            </div>
        </div>);
}

const BouncingDotsLoader = (props: any) => {
    return (
        <div className="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default AoIMap;

