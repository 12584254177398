import React from 'react';
import Map from '../map/Map';
import AoIMap from '../map/AoIMap';
import { BASINS, COUNTIES } from '../map/redux/types';

interface IMapComponentProps {
    mapType: MapType
    allowCreateAoI?: boolean;
    allowSelection?: boolean;
    disableNavigation?: boolean;
    disablePopup?: boolean;
    zoomType?: "hidden" | "default" | "large";
    defaultSelectedLayerType?: typeof BASINS | typeof COUNTIES;
    customExtent?: __esri.Extent;
}

export enum MapType {
    AOI,
    Cart
}

export const MapComponent = (props: IMapComponentProps) => {
    const getMapType = () => {
        if (props?.mapType === MapType.AOI) {
            return (<AoIMap allowCreateAoI={props?.allowCreateAoI ? props.allowCreateAoI : false} />)
        }
        if (props?.mapType === MapType.Cart) {
            return (
                <Map
                    allowSelection={
                        props?.allowSelection ? props.allowSelection : false
                    }
                    customExtent={props.customExtent}
                    defaultSelectedLayerType={props.defaultSelectedLayerType}
                    disableNavigation={props.disableNavigation}
                    disablePopup={props.disablePopup}
                    zoomType={props.zoomType}
                />
            );
        }
        return (<div />)
    }

    return (
        <React.Fragment>
            <figure>
                {getMapType()}
            </figure>
        </React.Fragment>
    )
}
