import React, { useState, useMemo, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { Link } from "react-router-dom";
import { getSelectedWell, addHours, formatDateMMDDYYYY, addCommasToNumber } from "./AoiUtils"
import xlsx, { IContent } from 'json-as-xlsx'
import moment from "moment";
import {
    handleProductionTabIndex,
    handleSetLeaseProductionDataLoading,
    handleSetProductionDataLoading
} from "../store/actions/wells-rigs-action";
import GlobalTable from "../common/GlobalTable";
import { GlobalTableProps } from "../models/page-props";
import useProductionDataCol from "./ProductionnTableColumns";
import {
    ProductionObjectType,
    ProductionObjectNestedType,
    tableColObje,
    LeaseProductionObjectType,
    LeaseProductionObjectNestedType,
} from '../models/redux-models'
import { handleFreeTrialDownAlertMsgModal } from "../store/actions/modal-actions";
import Renderers from "../map/config/carto/Renderers";

const AnalyticsRightSidebarWellAttributesTab = () => {
    const {
        auth: {
            user: {
                company_configs: { download_enabled, free_trial_period_enabled }
            },
        },
        segments: {
            activeSegmentList: { data: segmentData }
        },
        wellsAndRigs: {
            selectedRowId,
            wellsData: { data: wellsDataList },
            analyticsData: { oil_data, gas_data, },
            leaseProductionData,
            productionDataLoading,
            leaseProductionDataLoading,
            productionTabIndex,
            selectedWellRigOrPermitUIDList
        },
    } = useAppSelector((state) => state);

    const dispatch = useAppDispatch();
    type DownloadCSVDataProduction = { production_month: string, oil_prod: string, gas_prod: string }
    type DownloadCSVDataLease = { production_month: string, lease_oil_prod: string, lease_gas_prod: string, lease_oil_disposition: string, lease_gas_disposition: string }
    const [totalOilProduction, setTotalOilProduction] = useState<number | string>('')
    const [totalGasProduction, setTotalGasProduction] = useState<number | string>('')

    const [leaseOilProduction, setLeaseOilProduction] = useState<number | string>('')
    const [leaseGasProduction, setLeaseGasProduction] = useState<number | string>('')
    const [leaseOilDisposition, setLeaseOilDisposition] = useState<number | string>('')
    const [leaseGasDisposition, setLeaseGasDisposition] = useState<number | string>('')
    const [productionDataLoadingComponent, setProductionDataLoadingComponent] = useState<boolean>(true)
    const [leaseProductionDataLoadingComponent, setLeaseProductionDataLoadingComponent] = useState<boolean>(true)
    const productionColumnData: tableColObje[] = [
        { label: 'Production Month', status: true, header: 'Production&nbsp;Month' },
        { label: 'Liquids Prod (BBL)', status: true, header: 'Liquids Prod&nbsp;(BBL)' },
        { label: 'Gas Prod (MCF)', status: true, header: 'Gas Prod&nbsp;(MCF)' }
    ]
    const cumulativeProductionColumnData: tableColObje[] = [
        { label: totalOilProduction || totalGasProduction ? 'Total&NBSP;' : '&NBSP;', status: true, header: totalOilProduction || totalGasProduction ? 'Total&NBSP;' : '&NBSP;' },
        { label: `${totalOilProduction.toString()}&NBSP;`, status: true, header: `${totalOilProduction.toString()}&NBSP;` },
        { label: `${totalGasProduction.toString()}&NBSP;`, status: true, header: `${totalGasProduction.toString()}&NBSP;` }
    ]
    const leaseProductionColumnData: tableColObje[] = [
        { label: 'Production Month', status: true, header: 'Production&nbsp;Month' },
        { label: 'Liquids Prod (BBL)', status: true, header: 'Liquids Prod&nbsp;(BBL)' },
        { label: 'Gas Prod (MCF)', status: true, header: 'Gas Prod&nbsp;(MCF)' },
        { label: 'Liquids Disp (BBL)', status: true, header: 'Liquids Disp&nbsp;(BBL)' },
        { label: 'Gas Disp (MCF)', status: true, header: 'Gas Disp&nbsp;(MCF)' }
    ]
    const cumulativeLeaseProductionColumnData: tableColObje[] = [
        { label: leaseOilProduction || leaseGasProduction ? 'TOTAL&NBSP;' : "&NBSP;", status: true, header: leaseOilProduction || leaseGasProduction ? 'TOTAL&NBSP; ' : "&NBSP;" },
        { label: `${leaseOilProduction.toString()}&NBSP;`, status: true, header: `${leaseOilProduction.toString()}&NBSP;` },
        { label: `${leaseOilDisposition.toString()}&NBSP;`, status: true, header: `${leaseOilDisposition.toString()}&NBSP;` },
        { label: `${leaseGasProduction.toString()}&NBSP;`, status: true, header: `${leaseGasProduction.toString()}&NBSP;` },
        { label: `${leaseGasDisposition.toString()}&NBSP;`, status: true, header: `${leaseGasDisposition.toString()}&NBSP;` }
    ]

    /* 
    I am choosing to have two loading variables for the production and lease data.  
    There is already the productionDataLoading and leaseProductionDataLoading in redux.
    These state variables are set to false, after the api call is complete and the production
    data is updated in redux.  I want to maintain those defintitions throughout the app 
    (redux loading variables are false after data is retrieved from the backend.)
    However, just making the api call successfully doesn't mean the card can shift from loading
    from rendering the table, because there are multiple hooks that need to be triggered for 
    data processing.  Therefore, I'm creating separate loading variables at this component level
    (productionDataLoadingComponent and leaseProductionDataLoadingComponent).  They are true
    if the redux ones are true, otherwise, are only set after the last hook has processed the data
    (or there isn't data to process)
    */
    useEffect(() => {
        if (productionDataLoading) {
            setProductionDataLoadingComponent(true)
        }
    }, [productionDataLoading])
    useEffect(() => {
        if (leaseProductionDataLoading) {
            setLeaseProductionDataLoadingComponent(true)
        }
    }, [leaseProductionDataLoading])

    const selectedWell = useMemo(() => {
        if (selectedWellRigOrPermitUIDList.length === 1) {
            return getSelectedWell(wellsDataList, selectedWellRigOrPermitUIDList[0])
        }
        return null
    }, [wellsDataList, selectedWellRigOrPermitUIDList])

    const selectedOilData = useMemo(() => {
        if (!productionDataLoading) {
            const selectedDataList = oil_data.filter((data) => data.name === selectedWell?.well_api)
            if (selectedDataList.length > 0) {
                return selectedDataList[0].values
            }
            // if production data loading is false, meaning the api call completed and should have gotten oil & gas data
            // but it doesn't have data, then turn loading to false.
            if ((!oil_data || oil_data.length === 0) && (!gas_data || gas_data.length === 0)) {
                setProductionDataLoadingComponent(false)
            }
        }

        return []
    }, [selectedWell, oil_data, oil_data[0]?.name, productionDataLoading])
    const selectedGasData = useMemo(() => {
        if (!productionDataLoading) {
            const selectedDataList = gas_data.filter((data) => data.name === selectedWell?.well_api)
            if (selectedDataList.length > 0) {
                return selectedDataList[0].values
            }
            // if production data loading is false, meaning the api call completed and should have gotten oil & gas data
            // but it doesn't have data, then turn loading to false.
            if ((!oil_data || oil_data.length === 0) && (!gas_data || gas_data.length === 0)) {
                setProductionDataLoadingComponent(false)
            }
        }

        return []
    }, [selectedWell, gas_data, gas_data[0]?.name, productionDataLoading])

    const shouldShowLeaseTab = useMemo(() => {
        if (["TX", "LA", "OK", "KS"].includes(selectedWell?.state_abbr ?? "")) {
            return true;
        }
        return false
    }, [selectedWell])

    const combinedOilGasProdDataForTable = useMemo(() => {
        {/* The selected oil and gas data is a list of objects with a production_date key
        with a string date, and a production_quantity with a number value.  
        
        selectedOilData = [{production_date: '1992-01-02', production_quantity: 342}]

        We need a single object to combine oil and gas data with the key names to relate to the
        column headers: 'Production Month' 'Liquids Prod (BBL)' 'Gas Prod (MCF)'
        
        combinedData = [{'Production Month': 'Jan 1992', 'Liquids Prod (BBL)': 342, 'Gas Prod (MCF)': 0}]

        One tricky part is if there is data for one month in the oil data, but not data for gas.  We
        need the data in chronological order, so I am also adding a key / value of a javascript Date
        in order to sort by date at the end, so it stays in order. 

        The approach to creating this list, is to first create an object with a key of the production month.
        This will allow me to only loop through the selectedOilData and selectedGasData once.  Then, I can 
        make the final list from the keys of the temp object

        tempObject = {'Jan 1992': {'Production Month': 'Jan 1992', 'Liquids Prod (BBL)': 342, 'Gas Prod (MCF)': 0}}

        */}

        const tempObject: ProductionObjectType = {}
        const threeLetterMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        let totalOil: number = 0
        // I noticed that production is reported on the first of the month 1992-04-01
        // so if you make a JS Date from new Date('1992-04-01') is will be at midnight
        // on april 1st in UTC.  So if we convert that to Central time, then it's March 31 at 6pm
        // so then after some string formating the production would be reported on Mar, 1992.  
        // Therefore, I'm just adding 12 hours to the created datetimes from a string

        selectedOilData.forEach((selectedOilItem) => {
            const jsDate = addHours(new Date(selectedOilItem.production_date));
            const monthYear = `${threeLetterMonths[jsDate.getMonth()]} ${jsDate.getFullYear()}`
            if (!tempObject[monthYear]) {
                tempObject[monthYear] = {
                    'Production Month': monthYear,
                    'Liquids Prod (BBL)': addCommasToNumber(selectedOilItem.production_quantity),
                    'Gas Prod (MCF)': null,
                    jsDate,
                    id: 0
                }
            } else {
                tempObject[monthYear]['Liquids Prod (BBL)'] = addCommasToNumber(selectedOilItem.production_quantity)
            }
            totalOil += selectedOilItem.production_quantity
        })
        let totalGas: number = 0
        selectedGasData.forEach((selectedGasItem) => {
            const jsDate = new Date(selectedGasItem.production_date);
            const monthYear = `${threeLetterMonths[jsDate.getMonth()]} ${jsDate.getFullYear()}`
            if (!tempObject[monthYear]) {
                tempObject[monthYear] = {
                    'Production Month': monthYear,
                    'Liquids Prod (BBL)': null,
                    'Gas Prod (MCF)': addCommasToNumber(selectedGasItem.production_quantity),
                    jsDate,
                    id: 0
                }
            } else {
                tempObject[monthYear]['Gas Prod (MCF)'] = addCommasToNumber(selectedGasItem.production_quantity)
            }
            totalGas += selectedGasItem.production_quantity
        })
        const combinedDataList: ProductionObjectNestedType[] = []
        let id: number = 0;
        Object.keys(tempObject).forEach((tempKey: string) => {
            const newObj: ProductionObjectNestedType = tempObject[tempKey]
            // make sure both oil and gas production are not null for leading values.  If either
            // are "0" that is still truthy in JS
            if (combinedDataList.length > 0 || newObj["Gas Prod (MCF)"] || newObj["Liquids Prod (BBL)"]) {
                newObj.id = id;
                id += 1;
                combinedDataList.push(newObj)
            }

        })

        const combinedDataSorted = combinedDataList.sort(function (a, b) {
            return a.jsDate.getTime() - b.jsDate.getTime();
        })
        setTotalOilProduction(addCommasToNumber(totalOil))
        setTotalGasProduction(addCommasToNumber(totalGas))
        if (combinedDataSorted.length > 0) {
            dispatch(handleSetProductionDataLoading(false))
        }

        if (!productionDataLoading && ((selectedGasData && selectedGasData.length > 0) || (selectedOilData && selectedOilData.length > 0))) {
            // if production data loading is false, meaning the api call finished, and there is selected oil or gas data, meaning that 
            // use effect hook already ran and populated this data, but there is still not combined data, then we should set loading to false
            setProductionDataLoadingComponent(false)
        }
        return combinedDataSorted ?? []

    }, [selectedGasData, selectedOilData])

    const combinedOilGasLeaseDataForTable = useMemo(() => {

        const tempObject: LeaseProductionObjectType = {}
        const threeLetterMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        let totalOilProd: number = 0
        let totalGasProd: number = 0
        let totalOilDisp: number = 0
        let totalGasDisp: number = 0
        if (leaseProductionData.oilLeaseProduction) {
            leaseProductionData.oilLeaseProduction.forEach((oilLeaseProdItem) => {
                const jsDate = addHours(new Date(oilLeaseProdItem.formatted_date));
                const monthYear = `${threeLetterMonths[jsDate.getMonth()]} ${jsDate.getFullYear()}`
                if (!tempObject[monthYear]) {
                    tempObject[monthYear] = {
                        'Production Month': monthYear,
                        'Liquids Prod (BBL)': addCommasToNumber(oilLeaseProdItem.production_sum),
                        'Gas Prod (MCF)': null,
                        'Liquids Disp (BBL)': null,
                        'Gas Disp (MCF)': null,
                        jsDate,
                        id: 0
                    }
                } else {
                    tempObject[monthYear]['Liquids Prod (BBL)'] = addCommasToNumber(oilLeaseProdItem.production_sum)
                }
                totalOilProd += oilLeaseProdItem.production_sum
            })
        }
        if (leaseProductionData.gasLeaseProduction) {
            leaseProductionData.gasLeaseProduction.forEach((gasLeaseProdItem) => {
                const jsDate = addHours(new Date(gasLeaseProdItem.formatted_date));
                const monthYear = `${threeLetterMonths[jsDate.getMonth()]} ${jsDate.getFullYear()}`
                if (!tempObject[monthYear]) {
                    tempObject[monthYear] = {
                        'Production Month': monthYear,
                        'Liquids Prod (BBL)': null,
                        'Gas Prod (MCF)': addCommasToNumber(gasLeaseProdItem.production_sum),
                        'Liquids Disp (BBL)': null,
                        'Gas Disp (MCF)': null,
                        jsDate,
                        id: 0
                    }
                } else {
                    tempObject[monthYear]['Gas Prod (MCF)'] = addCommasToNumber(gasLeaseProdItem.production_sum)
                }
                totalGasProd += gasLeaseProdItem.production_sum
            })
        }
        if (leaseProductionData.oilLeaseDisposition) {
            leaseProductionData.oilLeaseDisposition.forEach((oilLeaseDispItem) => {
                const jsDate = addHours(new Date(oilLeaseDispItem.formatted_date));
                const monthYear = `${threeLetterMonths[jsDate.getMonth()]} ${jsDate.getFullYear()}`
                if (!tempObject[monthYear]) {
                    tempObject[monthYear] = {
                        'Production Month': monthYear,
                        'Liquids Prod (BBL)': null,
                        'Gas Prod (MCF)': null,
                        'Liquids Disp (BBL)': addCommasToNumber(oilLeaseDispItem.production_sum),
                        'Gas Disp (MCF)': null,
                        jsDate,
                        id: 0
                    }
                } else {
                    tempObject[monthYear]['Liquids Disp (BBL)'] = addCommasToNumber(oilLeaseDispItem.production_sum)
                }
                totalOilDisp += oilLeaseDispItem.production_sum
            })
        }
        if (leaseProductionData.gasLeaseDisposition) {
            leaseProductionData.gasLeaseDisposition.forEach((gasLeaseDispItem) => {
                const jsDate = addHours(new Date(gasLeaseDispItem.formatted_date));
                const monthYear = `${threeLetterMonths[jsDate.getMonth()]} ${jsDate.getFullYear()}`
                if (!tempObject[monthYear]) {
                    tempObject[monthYear] = {
                        'Production Month': monthYear,
                        'Liquids Prod (BBL)': null,
                        'Gas Prod (MCF)': null,
                        'Liquids Disp (BBL)': addCommasToNumber(gasLeaseDispItem.production_sum),
                        'Gas Disp (MCF)': null,
                        jsDate,
                        id: 0
                    }
                } else {
                    tempObject[monthYear]['Liquids Disp (BBL)'] = addCommasToNumber(gasLeaseDispItem.production_sum)
                }
                totalOilDisp += gasLeaseDispItem.production_sum
            })
        }
        const combinedDataList: LeaseProductionObjectNestedType[] = []
        let id: number = 0;
        Object.keys(tempObject).forEach((tempKey: string) => {
            const newObj: LeaseProductionObjectNestedType = tempObject[tempKey]
            // remove the leading null values
            if (combinedDataList.length > 0 || newObj["Liquids Prod (BBL)"] || newObj["Gas Prod (MCF)"] || newObj["Liquids Disp (BBL)"] || newObj["Gas Disp (MCF)"]) {
                newObj.id = id;
                id += 1;
                combinedDataList.push(newObj)
            }
        })

        const combinedDataSorted = combinedDataList.sort(function (a, b) {
            return a.jsDate.getTime() - b.jsDate.getTime();
        })
        setLeaseOilProduction(addCommasToNumber(totalOilProd))
        setLeaseOilDisposition(addCommasToNumber(totalOilDisp))
        setLeaseGasProduction(addCommasToNumber(totalGasProd))
        setLeaseGasDisposition(addCommasToNumber(totalGasDisp))
        dispatch(handleSetLeaseProductionDataLoading(false))

        if (!leaseProductionDataLoading) {
            // if the lease production data loading is false, meaning the api call completed, but 
            // we were not able to process and data, then we should turn off loading.
            setLeaseProductionDataLoadingComponent(false)
        }
        return combinedDataSorted

    }, [leaseProductionData])

    const wellDetails = [
        {
            name: 'OPERATOR',
            value: selectedWell?.operator_name
        },
        {
            name: 'WELL NAME',
            value: selectedWell?.well_name
        },
        {
            name: 'API',
            value: selectedWell?.well_api
        },
        {
            name: 'ORIENTATION',
            value: selectedWell?.drill_type
        },
        {
            name: 'RESERVOIR',
            value: selectedWell?.reservoir ?? ''
        },
        {
            name: 'COUNTY',
            value: selectedWell?.county
        },
        {
            name: 'STATE',
            value: selectedWell?.state_name
        },
        {
            name: 'WELL STATUS',
            value: selectedWell?.well_status
        },
        {
            name: 'PERMIT DATE',
            value: formatDateMMDDYYYY(selectedWell?.permit_date ?? '')
        },
        {
            name: 'SPUD DATE',
            value: formatDateMMDDYYYY(selectedWell?.spud_date ?? '')
        },
        {
            name: 'COMPLETION DATE',
            value: formatDateMMDDYYYY(selectedWell?.completion_date ?? '')
        },

    ]

    useEffect(() => {
        // if there the well is in a state with a lease tab, and the lease tab is not currently selected,
        // and we finished loading the lease and production data, and there is not production data but there
        // is lease data, then switch to the lease data.
        if (shouldShowLeaseTab && productionTabIndex !== 1 && !productionDataLoading && !leaseProductionDataLoading && (!combinedOilGasProdDataForTable || combinedOilGasProdDataForTable.length === 0) && (combinedOilGasLeaseDataForTable && combinedOilGasLeaseDataForTable.length > 0)) {
            dispatch(handleProductionTabIndex(1))
        }
    }, [shouldShowLeaseTab, productionTabIndex, productionDataLoadingComponent, leaseProductionDataLoadingComponent])

    const productionTableColumnData = useProductionDataCol(leaseProductionColumnData) as GlobalTableProps["cols"]
    const leaseTableColumnData = useProductionDataCol(productionColumnData) as GlobalTableProps["cols"]

    const tableColumnData = () => {
        if (shouldShowLeaseTab && productionTabIndex === 1) {
            return productionTableColumnData
        }
        return leaseTableColumnData
    }

    const tableData = useMemo(() => {
        if (shouldShowLeaseTab && productionTabIndex === 1) {
            return combinedOilGasLeaseDataForTable
        }
        return combinedOilGasProdDataForTable
    }, [shouldShowLeaseTab, productionTabIndex, combinedOilGasLeaseDataForTable, combinedOilGasProdDataForTable])


    const emptyPlaceholder = <>
        {productionDataLoadingComponent ? <div className="full-center-column"><span>Loading ...</span></div> : <div className="full-center-column">
            <img src="images/barrel-crossed-out.svg" alt="Pumpjack" className="margin-bottom-point-3" /> <span>Production Data Unavailable
            </span>{" "}
        </div>}</>
    const emptyPlaceholderLease = <>{leaseProductionDataLoadingComponent ? <div className="full-center-column"><span>Loading ...</span></div> : <div className="full-center-column">
        <img src="images/barrel-crossed-out.svg" alt="Pumpjack" className="margin-bottom-point-3" /> <span>Production Data Unavailable
        </span>{" "}
    </div>}</>


    const wellStatusAndSymbol = useMemo(() => {
        if (!selectedWell) {
            return ''
        }

        let wellInfoList = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.filter((info: any) => info.value.toString() === selectedWell.parsed_status_id.toString())
        if (wellInfoList.length === 0) {
            // return Other if it's not anything else
            wellInfoList = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.filter((info: any) => info.value.toString() === "10")

        }
        return (<div className="gap-3 well-status-symbol">
            <img src={`${wellInfoList[0].symbol.url}`} alt="well-symbol" className="well-symbol-wellcard-header" />
            {wellInfoList[0].label}
        </div>)
    }, [selectedWell])

    return (

        <div className="column-flex-1">
            <Scrollbars
                className="card-section-scroll-production"

                renderThumbVertical={(props) => (
                    <div {...props} className="thumb-vertical" />
                )}
                renderTrackVertical={(props) => (
                    <div {...props} className="track-vertical" />
                )}
            >
                <div className="tab-content tabSection" id="myTabContentaoi" style={{ paddingRight: '1rem' }}>
                    <div
                        className="production-analytics-box"
                        id="productionAnalytics"
                        role="tabpanel"
                        aria-labelledby="productionAnalytics-tab"
                    >
                        <div className="space-between-row">
                            <div className="wellAttrTitleHeader">
                                <div className="wellTitle">{selectedWell && selectedWell.well_name}</div>
                                <div className="apiTitle">{selectedWell && `API: (${selectedWell.well_api})`}</div>
                            </div>

                            {wellStatusAndSymbol}

                        </div>
                        <div className="card-box-production">
                            <div className="card-box-header-production-y-padding" >
                                <div className="label-heading-well-info">Well Information</div>
                                <a href={selectedWell?.state_link ?? ''} target={selectedWell?.state_link ? "_blank" : "_self"} className={selectedWell?.state_link ? "wellAttrTopButton" : "wellAttrTopButtonDisabled"} > <img src="images/link-connected.svg" alt="State Link" className="iconImage" />{" "}
                                    State Link</a>
                            </div>
                            {wellDetails.map((wellDetail) => (
                                <div className="wellAttrTable">
                                    <div className="leftBox">{wellDetail.name}</div>
                                    <div className="rightBox">{wellDetail.value ? wellDetail.value.toString().toUpperCase() : ''}</div>
                                </div>
                            ))}
                        </div>
                        <div className="card-box-production-top-padding">
                            <div className="card-box-header-production-x-padding">
                                {shouldShowLeaseTab ? <div className="analyticsTab" style={{ borderBottom: 'none' }}>
                                    <ul className="nav" id="myTabaoi" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={productionTabIndex === 0 ? "nav-link active" : "nav-link"}
                                                id="productionAnalytics-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#productionAnalytics"
                                                type="button"
                                                role="tab"
                                                onClick={() => {
                                                    if (productionTabIndex !== 0) {
                                                        dispatch(handleProductionTabIndex(0))
                                                    }
                                                }}
                                                aria-controls="productionAnalytics"
                                                aria-selected={productionTabIndex === 0}
                                            >
                                                <span className="text">Well Production</span>{" "}
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={productionTabIndex === 1 ? "nav-link active" : "nav-link"}
                                                id="operators-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#operators"
                                                type="button"
                                                role="tab"
                                                aria-controls="operators"
                                                onClick={() => {

                                                    if (productionTabIndex !== 1) {
                                                        dispatch(handleProductionTabIndex(1))
                                                    }

                                                }}
                                                aria-selected={productionTabIndex === 1}
                                            >
                                                <span className="text">Lease Production</span>{" "}
                                            </button>
                                        </li>

                                    </ul>
                                </div> : <div className="label-heading-well-info">Well Production</div>}

                                <div className="action-btn-production">
                                    <Link to="" onClick={() => {
                                        if (free_trial_period_enabled && !download_enabled) {
                                            dispatch(handleFreeTrialDownAlertMsgModal(true))
                                            return
                                        }
                                        const tempDataLease: DownloadCSVDataLease[] = [];
                                        const tempDataProduction: DownloadCSVDataProduction[] = [];
                                        if (shouldShowLeaseTab && productionTabIndex === 1) {

                                            combinedOilGasLeaseDataForTable.forEach((combinedItem) => {
                                                tempDataLease.push({
                                                    production_month: combinedItem['Production Month'],
                                                    lease_oil_prod: combinedItem['Liquids Prod (BBL)'] || combinedItem['Liquids Prod (BBL)'] === '0' ? combinedItem['Liquids Prod (BBL)'].toString() : '',
                                                    lease_gas_prod: combinedItem['Gas Prod (MCF)'] || combinedItem['Gas Prod (MCF)'] === '0' ? combinedItem['Gas Prod (MCF)'].toString() : '',
                                                    lease_oil_disposition: combinedItem['Liquids Disp (BBL)'] || combinedItem['Liquids Disp (BBL)'] === '0' ? combinedItem['Liquids Disp (BBL)'].toString() : '',
                                                    lease_gas_disposition: combinedItem['Gas Disp (MCF)'] || combinedItem['Gas Disp (MCF)'] === '0' ? combinedItem['Gas Disp (MCF)'].toString() : '',
                                                })
                                            })
                                            // setState((prev) => ({ ...prev, dowDataLease: tempData }))
                                        } else {

                                            combinedOilGasProdDataForTable.forEach((combinedItem) => {
                                                tempDataProduction.push({
                                                    production_month: combinedItem['Production Month'],
                                                    oil_prod: combinedItem['Liquids Prod (BBL)'] || combinedItem['Liquids Prod (BBL)'] === '0' ? combinedItem['Liquids Prod (BBL)'].toString() : '',
                                                    gas_prod: combinedItem['Gas Prod (MCF)'] || combinedItem['Gas Prod (MCF)'] === '0' ? combinedItem['Gas Prod (MCF)'].toString() : ''
                                                })
                                            })
                                            // setState((prev) => ({ ...prev, dowDataProduction: tempData }))
                                        }
                                        let fileName = `${shouldShowLeaseTab && productionTabIndex === 1 ? 'Lease' : 'Well'}_Production_Export_${getSelectedWell(wellsDataList, selectedRowId)?.well_api}_${moment(new Date()).format(
                                            "MMM-DD-YYYY, h:mm:ss a"
                                        )}`;
                                        let excel_data = [

                                            {
                                                sheet: "Well Production",
                                                columns: shouldShowLeaseTab && productionTabIndex === 1 ? [
                                                    { label: "Production Month", value: "production_month" },
                                                    { label: "Liquids Prod (BBL)", value: "lease_oil_prod" },
                                                    { label: "Gas Prod (MCF)", value: "lease_gas_prod" },
                                                    { label: "Liquids Disp (BBL)", value: "lease_oil_disposition" },
                                                    { label: "Gas Disp (MCF)", value: "lease_gas_disposition" },
                                                ] : [
                                                    { label: "Production Month", value: "production_month" },
                                                    { label: "Liquids Prod (BBL)", value: "oil_prod" },
                                                    { label: "Gas Prod (MCF)", value: "gas_prod" },
                                                ],
                                                content: (shouldShowLeaseTab && productionTabIndex === 1 ? tempDataLease as IContent[] : tempDataProduction as IContent[]),
                                            },
                                        ]

                                        xlsx(excel_data, {
                                            fileName,
                                            extraLength: 5,
                                        })
                                    }}>

                                        <img src="images/download.svg" alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="column-min-height-22">
                                <Scrollbars
                                    className="card-section-scroll-production"
                                    renderThumbVertical={(props) => (
                                        <div {...props} className="thumb-vertical" />
                                    )}
                                    renderTrackVertical={(props) => (
                                        <div {...props} className="track-vertical" />
                                    )}
                                >
                                    <GlobalTable
                                        tableClassName="production-table-body"
                                        cols={tableColumnData()}
                                        loadingMsg={productionDataLoading ? "Loading..." : undefined}
                                        data={tableData}
                                        trClassName="production-table-row"
                                        showColGroup={true}
                                        emptyPlaceholder={
                                            shouldShowLeaseTab && productionTabIndex === 1 ?
                                                emptyPlaceholderLease : emptyPlaceholder}
                                    />
                                </Scrollbars>
                                <GlobalTable
                                    tableClassName="production-table-body"
                                    cols={useProductionDataCol(shouldShowLeaseTab && productionTabIndex === 1 ? cumulativeLeaseProductionColumnData : cumulativeProductionColumnData) as GlobalTableProps["cols"]}
                                    loadingMsg={"Loading..."}
                                    // rowId={tabIndex === 0 ? (selectedRowId as string) : (selectedRowId as number)}
                                    emptyPlaceholder=" "
                                    data={[]}
                                    trClassName="production-table-row"
                                    showColGroup={true}
                                    headStyle={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-around',
                                    }}

                                />
                            </div>
                        </div>
                    </div >

                    <div
                        className="tab-pane fade"
                        id="welltype"
                        role="tabpanel"
                        aria-labelledby="welltype-tab"
                    >
                        <div className="tabBlockContent">Well</div>
                    </div></div>
            </Scrollbars>
        </div >

    );
};

export default AnalyticsRightSidebarWellAttributesTab;
