import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { cartBasinState } from "../../models/redux-models";

const initialState: cartBasinState = {
    // well_status_list: [],
    // operator_list: [],
    state_list: [],
    // county_list: [],
    // basin_list: [],
    basinSearchList: [],
    countySearchList: [],
    wellApiListAfterCsvUpload: [],
    sliderMinMaxValue: {
        measured_depth: 40000,
        true_vertical_depth: 30000,
        lateral_length: 30000,
        minMeasuredDepth: 0,
        minTrueVerticalDepth: 0,
        minLateralLength: 0,
        maxMeasuredDepth: 40000,
        maxTrueVerticalDepth: 30000,
        maxLateralLength: 30000,
        dataLoading: true,
    },
    leftTab: true,
    clearAllFilter: false,
    showAdvancedFilter: false,
    savedFilter: {
        openSavedFilter: false,
        saveFiltersDataLoading: true,
        filterID: 0,
        saveFiltersList: null,
    },
};

export const cartBasinToCountySlice = createSlice({
    name: "cartbasin",
    initialState,
    reducers: {
        clearSearchList(state, action: PayloadAction) {
            return {
                ...state,
                basinSearchList: [],
                countySearchList: [],
            };
        },
        fetchBasinSearchList(
            state,
            action: PayloadAction<cartBasinState["basinSearchList"]>
        ) {
            return {
                ...state,
                countySearchList: [],
                basinSearchList: action.payload,
            };
        },
        handleWellApiListAfterCsvUpload(
            state,
            action: PayloadAction<cartBasinState["wellApiListAfterCsvUpload"]>
        ) {
            return {
                ...state,
                wellApiListAfterCsvUpload: action.payload,
            };
        },
        handleSliderValue(
            state,
            action: PayloadAction<cartBasinState["sliderMinMaxValue"]>
        ) {
            return {
                ...state,
                sliderMinMaxValue: {
                    ...state.sliderMinMaxValue,
                    ...action.payload,
                },
            };
        },
        toggleLeftTab(state, action: PayloadAction<cartBasinState["leftTab"]>) {
            return {
                ...state,
                leftTab: action.payload,
            };
        },
        handleStateList(
            state,
            action: PayloadAction<cartBasinState["state_list"]>
        ) {
            return {
                ...state,
                state_list: action.payload,
            };
        },
        handleClearAllFilter(
            state,
            action: PayloadAction<cartBasinState["clearAllFilter"]>
        ) {
            return {
                ...state,
                clearAllFilter: action.payload,
            };
        },
        handleAdvancedFilter(
            state,
            action: PayloadAction<cartBasinState["showAdvancedFilter"]>
        ) {
            return {
                ...state,
                showAdvancedFilter: action.payload,
            };
        },
        handleSavedFilter(
            state,
            action: PayloadAction<{
                openSavedFilter?: cartBasinState["savedFilter"]["openSavedFilter"];
                saveFiltersDataLoading?: cartBasinState["savedFilter"]["saveFiltersDataLoading"];
                saveFiltersList?: cartBasinState["savedFilter"]["saveFiltersList"];
                filterID?: cartBasinState["savedFilter"]["filterID"];
                reset?: boolean;
            }>
        ) {
            return {
                ...state,
                savedFilter: {
                    ...state.savedFilter,
                    ...("openSavedFilter" in action.payload && {
                        openSavedFilter: action.payload.openSavedFilter,
                    }),
                    ...("saveFiltersDataLoading" in action.payload && {
                        saveFiltersDataLoading:
                            action.payload.saveFiltersDataLoading,
                    }),
                    ...("saveFiltersList" in action.payload && {
                        saveFiltersList: action.payload.saveFiltersList,
                    }),
                    ...("reset" in action.payload && {
                        ...initialState["savedFilter"],
                    }),
                    ...("filterID" in action.payload && {
                        filterID: action.payload.filterID,
                    }),
                },
            };
        },
    },
});

export default cartBasinToCountySlice;
