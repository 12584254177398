import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { ProductionDataObj, RigsDataObj, WellsAndPermitsObject } from "../models/redux-models";
import {
    ANALYTICS_CUM_TAB, GAS, ANALYTICS_MONTHLY_TAB, OIL,
    LINE_CHART_XAXIS_FILTERS, getHeaderOfCSV,
} from "../../utils/helper";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { handleFreeTrialDownAlertMsgModal } from "../store/actions/modal-actions";
import { isInstanceOfWellsAndPermitsObject } from "../common/Map/helper"
import { getLineChartData, mergeAndSortOilGasData } from "../../Helper/commonFunction";

type PropsType = {
    iconSize: string;
}
const DownloadProductionLink = (props: PropsType) => {
    const { iconSize } = props;
    const {
        wellsAndRigs: {
            analyticsData: {
                forecastingData: { dataList },
                oil_data,
                gas_data,
                type,
                cum_gas_data,
                cum_oil_data,
                xAxisFilter,
                xAxisFilterCum,
                normalized,
                action,
                action_cum,
                apiListObj,
                selectedForecastPoint,
            },
            openForeCast,
            selectedWellRigOrPermitList,
            tabIndex,
        },
        auth: { user: { company_configs: { download_enabled, free_trial_period_enabled } } },
    } = useAppSelector((state) => state);

    const dispatch = useAppDispatch();
    const typeCurve = (xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"] || xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"]) ? true : false;

    return (
        <>

            <Link to="" onClick={() => {
                if (free_trial_period_enabled && !download_enabled) {
                    dispatch(handleFreeTrialDownAlertMsgModal(true))
                    return
                }
                let oilDataForDownload: any[] = getLineChartData(
                    dataList,
                    normalized,
                    tabIndex,
                    type,
                    ANALYTICS_MONTHLY_TAB,
                    oil_data,
                    cum_oil_data,
                    OIL,
                    OIL,
                    GAS,
                    gas_data,
                    cum_gas_data,
                    selectedWellRigOrPermitList,
                    selectedForecastPoint,
                    openForeCast,
                    type !== ANALYTICS_CUM_TAB ? false : xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"] ? false : true,
                    action,
                    action_cum,
                    apiListObj)
                let gasDataForDownload = getLineChartData(
                    dataList,
                    normalized,
                    tabIndex,
                    type,
                    ANALYTICS_MONTHLY_TAB,
                    oil_data,
                    cum_oil_data,
                    GAS,
                    OIL,
                    GAS,
                    gas_data,
                    cum_gas_data,
                    selectedWellRigOrPermitList,
                    selectedForecastPoint,
                    openForeCast,
                    (type === ANALYTICS_MONTHLY_TAB && xAxisFilter === LINE_CHART_XAXIS_FILTERS["Producing Time"]) || (type === ANALYTICS_CUM_TAB && xAxisFilterCum === LINE_CHART_XAXIS_FILTERS["Producing Time"]) ? false : true,
                    action,
                    action_cum,
                    apiListObj)

                const mergeDataForDownload = mergeAndSortOilGasData(oilDataForDownload, gasDataForDownload)
                let tempData: { well_api: string, production_date: string, production_quantity_oil: string, production_quantity_gas: string, production_month?: number }[] = [];

                // eslint-disable-next-line
                mergeDataForDownload.forEach((item: any) => {
                    let nonZero = false;
                    let index = 0;
                    let wellName = ''
                    selectedWellRigOrPermitList.forEach((well: WellsAndPermitsObject | RigsDataObj | ProductionDataObj) => {
                        if (isInstanceOfWellsAndPermitsObject(well) && well.well_name === item.name) {
                            wellName = `${well.well_api} - ${item.name}`
                        }
                    })
                    if (!wellName) {
                        wellName = item.name
                    }

                    item.values.forEach((_item: any) => {
                        if (
                            (_item.price === 0 ||
                                _item.price === null) &&
                            !nonZero
                        ) {
                        } else {
                            !nonZero && (nonZero = true);
                            tempData.push({
                                well_api: wellName,
                                production_date: `${_item.date}`,
                                production_quantity_oil: _item.production_quantity_oil ? _item.production_quantity_oil.toString() : '',
                                production_quantity_gas: _item.production_quantity_gas ? _item.production_quantity_gas.toString() : '',
                                production_month: index
                            })
                            index = index + 1
                        }
                    })
                })

                exportFromJSON({
                    data: tempData, fileName: `ED_Data_Export_${moment(new Date()).format(
                        "MMM-DD-YYYY, h:mm:ss a"
                    )}`,
                    exportType: "csv",
                    fields: [
                        (typeCurve ? "production_month" : "production_date"),
                        "well_api",
                        "production_quantity_oil",
                        "production_quantity_gas",
                    ],
                    beforeTableEncode: entries => entries.map(
                        ({ fieldName, fieldValues }) => {
                            return { fieldName: getHeaderOfCSV(fieldName, false, normalized, type, action, action_cum), fieldValues };
                        }
                    )
                })
            }}>
                {iconSize === "large" ? (<div className="downloadLink">
                    <img src="images/download.svg" alt="downloadImage" className="downloadImage" />
                </div>) : (<img src="images/download.svg" alt="downloadImage" />)}

            </Link>

        </>
    );
};

export default DownloadProductionLink;
