import React, { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
    clearFilterSearch,
    clearPermitData,
    clearProductionData,
    clearRigsData,
    clearWellsData,
    handleSelectedWellRigOrPermitUIDList,
    handleResizableWidth,
    // handlePageChange,
    handleShowAndHideSegmentDropDown,
    handleShowAndHideTable,
    // setFilterSearch,
    showHideAdvFilter,
    showHideColProperties,
    showHideCsvDownOpt,
    showHideFullScreen,
    toggleChooseColExportToCsvModal,
    toggleViewAnalytics,
    handleAnalyticsTabIndex
} from "../../store/actions/wells-rigs-action";
import ColFilterOption from "./ColFilterOption";
// import ExportToCsvOpt from "./ExportToCsvOpt";
import { handleClearAllFilter, toggleLeftTab } from "../../store/actions/cart-basin-to-county-actions";
import {
    ANALYTICS_DEFAULT_WIDTH,
    aoiPathname, modifyString,
    searchPathname
} from "../../../utils/helper";
// import { ProductionDataObj, RigsDataObj, WellsAndPermitsObject } from "../../models/redux-models";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../Spinner";
import { fetchSegmentsList, fetchSelectedSegmentDetails } from "../../store/actions/segments-actions";
import { FilterDataType } from "../../segments/Content";
import { toast } from "react-toastify";
import FreeTrialDownAlertMsgModal from "../Modal/FreeTrialDownAlertMsgModal";
import { handleFreeTrialDownAlertMsgModal } from "../../store/actions/modal-actions";
import { isInstanceOfWellsAndPermitsObject } from "../Map/helper";
import { ProductionDataObj, RigsDataObj, WellsAndPermitsObject } from "../../models/redux-models";

function Filter({ hideAdvFilterBtn, hideTableFilters }: { hideAdvFilterBtn?: boolean, hideTableFilters?: boolean }) {
    const searchRef = React.createRef<HTMLInputElement>();
    const dispatch = useAppDispatch();
    const {
        wellsAndRigs: { colProperties,
            tabIndex,
            //  filterSearch,
            csvDownOpt,
            fullScreen,
            selectedWellRigOrPermitList,
            selectedWellRigOrPermitUIDList,
            fullScrnAnalytics,
            viewAnalytics,
            showTable,
            resizableWidth,
            chooseColExportToCsvModal,
            advFilter,
            wellsData: { data: wellsDataList }, permitsData: { data: permitDataList }, rigsData: { data: rigsDataList }, showSegmentDropDown, productionData: { data: productionDataList },
            analyticsTabIndex,

        },
        segments: {
            activeSegmentList: { data: segmentData, dataLoading, total_record, page_size },
            page,
            selectedRowId,
            selectedSegmentData
        },
        modal: { freeTrialDownAlertMsgModal },
        auth: { user: { access_token, company_configs: { download_enabled, free_trial_period_enabled } } },
        cartBasinToCounty: { clearAllFilter, leftTab },
    } = useAppSelector((state) => state);


    const clearSearch = () => {
        searchRef.current &&
            (searchRef.current.value = "");
        dispatch(clearFilterSearch());
        // note:- if clearAllFilter is true do not call reset data
        if (!clearAllFilter) {
            if (tabIndex === 0) {
                dispatch(clearWellsData());
            } else if (tabIndex === 1) {
                dispatch(clearRigsData());
            } else if (tabIndex === 2) {
                dispatch(clearPermitData());
            } else {
                dispatch(clearProductionData())
            }
        }

    }

    useEffect(() => {
        if (clearAllFilter) {
            clearSearch();
            dispatch(handleClearAllFilter(false))
        }
        // eslint-disable-next-line
    }, [clearAllFilter])

    // const onSearchChange = debounce(
    //     (e: React.ChangeEvent<HTMLInputElement>) => {
    //         const { value } = e.target;
    //         if (value === "") {
    //             if (tabIndex === 0) {
    //                 dispatch(setFilterSearch(""));
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearWellsData());
    //             } else if (tabIndex === 1) {
    //                 dispatch(setFilterSearch(""));
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearRigsData());
    //             } else if (tabIndex === 2) {
    //                 dispatch(setFilterSearch(""));
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearPermitData());
    //             } else {
    //                 dispatch(setFilterSearch(""));
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearProductionData());
    //             }
    //             return;
    //         }
    //         if (value.length >= 2) {
    //             dispatch(setFilterSearch(numberRegex.test(value.trim().replace(',', '')) ? value.trim().replace(',', '') : value.trim()));
    //             if (tabIndex === 0) {
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearWellsData());
    //             } else if (tabIndex === 1) {
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearRigsData());
    //             } else if (tabIndex === 2) {
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearPermitData());
    //             }
    //             else {
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearProductionData());
    //             }
    //         }
    //     },
    //     500
    // );
    // const onSearchChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    //     const { key } = e;
    //     console.log({ searchRef })
    //     if (key === "Enter") {
    //         if (searchRef.current && (searchRef.current.value === "")) {
    //             if (tabIndex === 0) {
    //                 dispatch(setFilterSearch(""));
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearWellsData());
    //             } else if (tabIndex === 1) {
    //                 dispatch(setFilterSearch(""));
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearRigsData());
    //             } else if (tabIndex === 2) {
    //                 dispatch(setFilterSearch(""));
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearPermitData());
    //             } else {
    //                 dispatch(setFilterSearch(""));
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearProductionData());
    //             }
    //             return;
    //         }
    //         if (searchRef.current && searchRef.current.value.length >= 1) {
    //             dispatch(setFilterSearch(numberRegex.test(searchRef.current.value.trim().replace(',', '')) ? searchRef.current.value.trim().replace(',', '') : searchRef.current.value.trim()));
    //             if (tabIndex === 0) {
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearWellsData());
    //             } else if (tabIndex === 1) {
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearRigsData());
    //             } else if (tabIndex === 2) {
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearPermitData());
    //             }
    //             else {
    //                 dispatch(handlePageChange(1));
    //                 dispatch(clearProductionData());
    //             }
    //         }
    //     }
    // }
    // useEffect(() => {
    //     searchRef.current && (searchRef.current.value = "");
    //     // eslint-disable-next-line
    // }, [tabIndex]);

    const fetchData = () => {
        dispatch(
            fetchSegmentsList(access_token, {
                type: "active",
                page: page + 1,
            })
        );
    };

    const [state, setState] = useState({
        hasMore: false,
        segmentName: "",
        segmentId: 0
    })
    const { hasMore, segmentName, segmentId } = state
    useEffect(() => {
        if (showSegmentDropDown && total_record) {
            let pages =
                Math.floor(total_record / page_size) +
                (total_record % page_size > 0 ? 1 : 0);
            setState((prev) => ({
                ...prev,
                hasMore: page < pages ? true : false,
            }));
        }

        // eslint-disable-next-line
    }, [total_record, page]);

    useEffect(() => {
        if (dataLoading && showSegmentDropDown) {
            dispatch(fetchSegmentsList(access_token, { type: "active", page }, true));
        }
        // eslint-disable-next-line
    }, [showSegmentDropDown])

    useEffect(() => {
        if (selectedSegmentData.length > 0) {
            advFilter && dispatch(showHideAdvFilter());

            let tempData = [...selectedSegmentData];
            tempData[0] = { ...tempData[0], group_cond: "4" };
            let tempTransformData: FilterDataType[] = [];
            tempData.forEach((item) => {
                if (item.group_cond) {
                    tempTransformData.push({
                        condition: item.option_choice,
                        groupCondition: item.group_cond,
                        filter: [
                            {
                                id: item.id,
                                segment_id: item.segment_id,
                                dataPoint: item.data_point,
                                fields: item.data_point_field,
                                operator: item.operator_choice,
                                //this value key will be over written by the below condition this is just done to overcome the typescript error
                                value: item.filter_value as any,
                                ...((item.data_point_field === "16" ||
                                    item.data_point_field === "17" ||
                                    item.data_point_field === "18") &&
                                    item.operator_choice === "14"
                                    ? {
                                        value: new Date(
                                            JSON.parse(
                                                modifyString(
                                                    item.filter_value as string
                                                )
                                            ).start
                                        ),
                                        endDate: new Date(
                                            JSON.parse(
                                                modifyString(
                                                    item.filter_value as string
                                                )
                                            ).end
                                        ),
                                    }
                                    : item.data_point_field === "7"
                                        ? {
                                            ...(item.operator_choice === "4" && {
                                                value: (
                                                    item.filter_value as string
                                                ).split("_")[0],
                                                upperValue: (
                                                    item.filter_value as string
                                                ).split("_")[1],
                                            }),
                                            ...(item.operator_choice !== "4" && {
                                                value: item.filter_value,
                                            }),
                                        }
                                        : item.data_point_field === "9"
                                            ? {
                                                value: (
                                                    item.filter_value as string
                                                ).split("_")[0],
                                                //   upperValue: `${
                                                //       (
                                                //           item.filter_value as string
                                                //       ).split("_")[1]
                                                //   } ( ${
                                                //       (
                                                //           item.filter_value as string
                                                //       ).split("_")[2]
                                                //   } )`,
                                                upperValue: {
                                                    label: (
                                                        item.filter_value as string
                                                    ).split("_")[1],
                                                    value: (
                                                        item.filter_value as string
                                                    ).split("_")[2],
                                                },
                                            }
                                            : { value: item.filter_value }),
                            },
                        ],
                    });
                } else {
                    let index = tempTransformData.length - 1;
                    tempTransformData[index] = {
                        ...tempTransformData[index],
                        filter: [
                            ...tempTransformData[index]?.filter,
                            {
                                id: item.id,
                                segment_id: item.segment_id,
                                dataPoint: item.data_point,
                                fields: item.data_point_field,
                                operator: item.operator_choice,
                                //this value key will be over written by the below condition this is just done to overcome the typescript error
                                value: item.filter_value as any,
                                ...((item.data_point_field === "16" ||
                                    item.data_point_field === "17" ||
                                    item.data_point_field === "18") &&
                                    item.operator_choice === "14"
                                    ? {
                                        value: new Date(
                                            JSON.parse(
                                                modifyString(
                                                    item.filter_value as string
                                                )
                                            ).start
                                        ),
                                        endDate: new Date(
                                            JSON.parse(
                                                modifyString(
                                                    item.filter_value as string
                                                )
                                            ).end
                                        ),
                                    }
                                    : item.data_point_field === "7"
                                        ? {
                                            ...(item.operator_choice === "4" && {
                                                value: (
                                                    item.filter_value as string
                                                ).split("_")[0],
                                                upperValue: (
                                                    item.filter_value as string
                                                ).split("_")[1],
                                            }),
                                            ...(item.operator_choice !== "4" && {
                                                value: item.filter_value,
                                            }),
                                        }
                                        : item.data_point_field === "9"
                                            ? {
                                                value: (
                                                    item.filter_value as string
                                                ).split("_")[0],
                                                //   upperValue: (
                                                //       item.filter_value as string
                                                //   ).split("_")[1],
                                                upperValue: {
                                                    label: (
                                                        item.filter_value as string
                                                    ).split("_")[2],
                                                    value: (
                                                        item.filter_value as string
                                                    ).split("_")[1],
                                                },
                                            }
                                            : { value: item.filter_value }),
                            },
                        ],
                    };
                }
            });
            localStorage.setItem(
                "advFilData",
                JSON.stringify({
                    data: tempTransformData,
                    segmentName,
                    segmentId
                })
            );
            dispatch(showHideAdvFilter());
        }
        // eslint-disable-next-line
    }, [JSON.stringify(selectedSegmentData)])

    const onlyWellsNotRigsList = useMemo(() => {
        return selectedWellRigOrPermitList.filter((wellRigOrProd: WellsAndPermitsObject | RigsDataObj | ProductionDataObj) => isInstanceOfWellsAndPermitsObject(wellRigOrProd))
    }, [selectedWellRigOrPermitList])

    return (
        <>
            <div className="search-bottom">
                <div className={hideAdvFilterBtn ? "d-none" : "search-bottom-left"}>
                    {/* <div className="search-frm">
                        <div className="api">
                            <input
                                className="form-control"
                                placeholder={`Search for specific ${tabIndex === 0
                                    ? "wells"
                                    : tabIndex === 1
                                        ? "rigs"
                                        : tabIndex === 2 ? "permits" : "production"
                                    }`}
                                // onChange={onSearchChange}
                                onKeyDown={onSearchChange}
                                name="search"
                                ref={searchRef}
                            />
                        </div>
                        <span className="error"></span>
                    </div> */}
                    <div
                        className="ad-filter"
                        onClick={() => {
                            fullScreen && dispatch(showHideFullScreen());
                            dispatch(showHideAdvFilter());
                        }}
                    >
                        <a
                            href="void:(0)"
                            onClick={(e) => e.preventDefault()}
                            className="btn"
                        >
                            <img src="images/ad-filter.svg" alt="" />
                            Advanced Filters
                            <span onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                dispatch(handleShowAndHideSegmentDropDown(!showSegmentDropDown));
                            }} className="down-arrow">&nbsp;</span>
                        </a>
                        {/*add open to open dropdown  */}
                        <div className={`dropdownMenu segmentsSection-scroll scrollSection ${showSegmentDropDown ? "open" : ""}`}
                            id={'segmentList'}
                            style={{
                                minHeight: 0,
                                // maxHeight: "calc(100vh - 50rem)",
                                maxHeight: "16rem"
                            }}
                        >
                            <InfiniteScroll
                                dataLength={segmentData.length}
                                next={fetchData}
                                hasMore={hasMore}
                                scrollThreshold={0.8}
                                loader={<Spinner />}
                                style={{ overflow: "hidden" }}
                                scrollableTarget={"segmentList"}
                            >
                                <h2>Saved Segments</h2>
                                <ul>
                                    {dataLoading ? <li><a href="void:(0)" onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>Loading...</a></li> :
                                        segmentData.map((item, index) => {
                                            return <li key={index}><a href="void:(0)" onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (
                                                    item.id !==
                                                    selectedRowId && tabIndex === 1
                                                ) {
                                                    advFilter && dispatch(showHideAdvFilter())
                                                    setState((prev) => ({ ...prev, segmentId: item.id, segmentName: item.segment_name }))
                                                    dispatch(
                                                        handleSelectedWellRigOrPermitUIDList(
                                                            [item.id.toString()]
                                                        )
                                                    );
                                                    dispatch(
                                                        fetchSelectedSegmentDetails(
                                                            access_token,
                                                            item.id
                                                        )
                                                    );
                                                } else {
                                                    dispatch(
                                                        handleSelectedWellRigOrPermitUIDList(
                                                            []
                                                        )
                                                    );
                                                    dispatch(showHideAdvFilter())
                                                }
                                            }}>{item.segment_name}{item.id === selectedRowId ? <span style={{ color: "#fff", background: "#16A15E", borderRadius: "100%", padding: "1px", fontSize: "10px", width: "16px", height: "16px", textAlign: "center" }}><i className="fa-solid fa-check"></i></span> : <></>}</a></li>
                                        })
                                    }
                                </ul>
                            </InfiniteScroll>

                        </div>
                    </div>
                </div>
                <div className={hideTableFilters ? "d-none" : "search-menu"}>
                    <ul>

                        <li className="download"
                            title={"Export Data"}
                        >
                            <a
                                href="void:(0)"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    fullScreen && dispatch(showHideFullScreen())
                                    chooseColExportToCsvModal &&
                                        dispatch(toggleChooseColExportToCsvModal());
                                    colProperties &&
                                        dispatch(showHideColProperties());
                                    if (!download_enabled && free_trial_period_enabled) {
                                        dispatch(handleFreeTrialDownAlertMsgModal(true));
                                        return
                                    }
                                    dispatch(showHideCsvDownOpt());
                                }}
                            >
                                <img src="images/search-icon3.svg" alt="" />
                                Download
                            </a>
                            {/* {selectedWellRigOrPermitList?.length && !csvDownOpt ? <span className="badge">{selectedWellRigOrPermitList.length}</span> : <></>} */}
                            {onlyWellsNotRigsList?.length ? <span className="badge">{onlyWellsNotRigsList.length}</span> : <></>}
                            {/* <ExportToCsvOpt selectedRowData={(selectedWellRigOrPermitList || []) as (WellsAndPermitsObject[] | RigsDataObj[] | ProductionDataObj[])} /> */}
                        </li>
                        <li
                            onClick={() => {

                                if (selectedWellRigOrPermitList.length) {
                                    leftTab && dispatch(toggleLeftTab(false));
                                    // CASE 1: If the sidecard tab is not 0, meaning that the sidecard would show the
                                    // analytics tab.  The expected behavior is that the card showing Well Attributes
                                    // would close (if not already).  Then we set the tab to 0 (Analytics), then we open 
                                    // the card back up. 
                                    if (analyticsTabIndex !== 0) {
                                        dispatch(toggleViewAnalytics(false));
                                        dispatch(handleAnalyticsTabIndex(0));
                                    }
                                    dispatch(toggleViewAnalytics(true));

                                } else {
                                    toast.info("Please select at-least one well.")
                                }
                            }}
                        >
                            <a
                                className="view-analytics"
                                onClick={(e) => e.preventDefault()}
                                href="void:(0)"
                            >
                                <img src="images/search-icon4.svg" alt="" />{" "}
                                Analytics
                            </a>
                            {/* <AnalyticsRightSidebar /> */}
                        </li>
                    </ul>
                    <ul className="actionMenuEnd">
                        <li
                            title="Choose Columns"
                        >
                            <a
                                href="void:(0)"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    dispatch(showHideColProperties());
                                    csvDownOpt &&
                                        dispatch(showHideCsvDownOpt());
                                }}
                            >
                                <img src="images/search-icon1.svg" alt="" />
                            </a>
                            <div
                                className={
                                    colProperties
                                        ? "column-properties-dropdown open"
                                        : "d-none"
                                }
                            >

                                <h2>
                                    <span>Column properties</span>
                                    <button className="btn"><img src="images/filter-icon.svg" alt="" /></button>

                                </h2>
                                <ColFilterOption />

                            </div>
                        </li>
                        <li
                            title={fullScreen ? "Minimize" : "Maximize"}
                        >
                            <a
                                href="void:(0)"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    !showTable && !fullScreen && dispatch(handleShowAndHideTable(!showTable))
                                    csvDownOpt && dispatch(showHideCsvDownOpt());
                                    !fullScreen &&
                                        advFilter &&
                                        dispatch(showHideAdvFilter());
                                    dispatch(showHideFullScreen());
                                }}
                            >
                                <img src="images/search-icon2.svg" alt="" />
                            </a>
                        </li>
                        <li>
                            <div className={`showhidebtn ${fullScrnAnalytics ? "disabled" : ""}`} onClick={() => {
                                !fullScrnAnalytics && dispatch(handleShowAndHideTable(!showTable))
                                if (resizableWidth !== ANALYTICS_DEFAULT_WIDTH && viewAnalytics) {
                                    dispatch(toggleViewAnalytics(true));
                                    dispatch(handleResizableWidth(ANALYTICS_DEFAULT_WIDTH));
                                }

                            }}>
                                {/* {showTable ? "Hide" : "Show"} */}
                                <button className="btn"> {showTable ? <img src="images/down-angle.svg" alt="" /> : <img src="images/up-arrow2.svg" alt="" />}</button>
                            </div>
                        </li>
                    </ul >
                </div >
            </div >
            {/* <div className="filterbySection">
                {filterSearch && (
                    <div className="filter-by">
                        Filter By:{" "}
                        <span>
                            {filterSearch}
                            <small
                                className="cursor"
                                onClick={() => {
                                    clearSearch()
                                }}
                            >
                                X
                            </small>
                        </span>
                    </div>
                )}
            </div> */}
            {freeTrialDownAlertMsgModal && <FreeTrialDownAlertMsgModal />}
        </>
    );
}

export default Filter;
