import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { renderPreviewHTML } from "@arcgis/core/symbols/support/symbolUtils";
import Symbols from "./config/carto/Symbols";
import Renderers from "./config/carto/Renderers";
import { IFilter, SET_FILTER, TOGGLE_FILTER_VALUE, toggleAoi, toggleRigs, toggleWells, togglePlssTlss, toggleCounties } from "./redux/filters";
import LayerIds from "./config/layers/LayerIds";
import FieldNames from "./config/layers/FieldNames";

import "./css/wellTypeFilterPanel.scss";
import { ToggleButton } from "react-bootstrap";
import Switch from "react-switch";


// I set these so we can change them quickly in the code below. They are for the layer and field we're working with here.
const offsetWellsLayerId = LayerIds.WellData;
const filteredField = FieldNames.wellDataPointsTypeId;

// In the case of the Well type filter, if all items are deselected we want all
// items filtered from the layer.
const filterAllIfEmpty:boolean = false;

interface IWellTypeFilterPanelProps {
    setFilter: any
}


function WellTypeFilterPanel(props:IWellTypeFilterPanelProps) {

    const dispatch = useAppDispatch();

    const [currentFilterLayers, setCurrentFilterLayers] = useState<[Number]>();

    const {
        layers, aoiEnabled, wellsEnabled, rigsEnabled, plssTlssEnabled, countiesEnabled
    } = useAppSelector((state) => state.filters);

    useEffect(() => {
        //TODO: Add in reseting all filters when sync between wells, panel and legend is coupled.
        //const renderers = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.map(v => v.value)
        //dispatch(SET_FILTER({layerId: offsetWellsLayerId, filterAllIfEmpty, value:renderers, field: filteredField}))
    }, [])

    useEffect(() => {
        if (offsetWellsLayerId in layers) {
            //@ts-ignore
            const layerFilters = layers[offsetWellsLayerId];
            setCurrentFilterLayers(layerFilters[filteredField]);
        }
    }, [layers])

    const selectAll = () => {
        const allIds = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.map(v => v.value);
        dispatch(SET_FILTER([{layerId: offsetWellsLayerId, filterAllIfEmpty, value:allIds, field: filteredField}]));
    }

    const delselectAll = () => {
        dispatch(SET_FILTER([{layerId: offsetWellsLayerId, filterAllIfEmpty, value:[], field: filteredField}]));
    }

    const wellItemClicked = (id: any) => {
        return;
        //Disable map ability to toggle legends, filters will be toggled based on left panel searches until complete
        //sync is available.
        //TODO: Re-enable once legend, well list and panel are all in sync.
        //dispatch(TOGGLE_FILTER_VALUE({layerId: offsetWellsLayerId, filterAllIfEmpty, value:id, field: filteredField}));
    }

    const rigValueInfo = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.find((valueInfo) => valueInfo.value == -100);
    const rigSymbol:any = Renderers.rigLegendSymbol;
    if (rigValueInfo) {
        const { value, label } = rigValueInfo;
        const node = document.createElement("div");
        const nodeClasses =
            currentFilterLayers && currentFilterLayers.indexOf(value) !== -1
                ? "wellTypeFilterListItem"
                : "wellTypeFilterListItem";
        node.setAttribute("class", nodeClasses);
        const imgSpan = document.createElement("span");
        imgSpan.setAttribute("class", "wellTypeRigIcon");
        renderPreviewHTML(rigSymbol, {
            node: imgSpan,
            size: 8
        });
        node.append(imgSpan);

        const labelSpan = document.createElement("span");
        labelSpan.setAttribute("class", "wellTypeLabel");
        labelSpan.append(label);
        node.append(labelSpan);
        
        rigSymbol.value = value;
        rigSymbol.label = label;
        rigSymbol.node = node;
    }

    const node = document.createElement("div");
    const nodeClasses = aoiEnabled ? "wellTypeFilterListItem"
            : "wellTypeFilterListItem";
    node.setAttribute("class", nodeClasses);
    const imgSpan = document.createElement("span");
    imgSpan.setAttribute("class", "wellTypeIconAoi");
    node.append(imgSpan);

    const labelSpan = document.createElement("span");
    labelSpan.setAttribute("class", "wellTypeLabel");
    labelSpan.append("AOI");
    node.append(labelSpan);

    const aoiSymbol = {
        value: -200,
        label: "AOI",
        node: node
    }

    const countyNode = document.createElement("div");
    const countyNodeClasses = countiesEnabled ? "wellTypeFilterListItem"
            : "wellTypeFilterListItem";
    countyNode.setAttribute("class", countyNodeClasses);
    const countyImgSpan = document.createElement("span");
    countyImgSpan.setAttribute("class", "wellTypeIconCounty");
    
    countyNode.append(countyImgSpan);

    const countyLabelSpan = document.createElement("span");
    
    countyLabelSpan.setAttribute("class", "wellTypeLabel");
    countyLabelSpan.append("Counties");
    countyNode.append(countyLabelSpan);

    const countySymbol = {
        value: -300,
        label: "Counties",
        node: countyNode
    }

    // const plssTlssNode = document.createElement("div");
    // const plssTlssNodeClasses = plssTlssEnabled ? "wellTypeFilterListItem"
    //         : "wellTypeFilterListItem";
    // plssTlssNode.setAttribute("class", plssTlssNodeClasses);
    // const plssTlssImgSpan = document.createElement("span");
    // plssTlssImgSpan.setAttribute("class", "wellTypeIconPlss");
    // plssTlssNode.append(plssTlssImgSpan);

    // const plssTlssLabelSpan = document.createElement("span");
    // plssTlssLabelSpan.setAttribute("class", "wellTypeLabel");
    // plssTlssLabelSpan.append("PLSS / TLSS");
    // plssTlssNode.append(plssTlssLabelSpan);

    // const plssTlssSymbol = {
    //     value: -400,
    //     label: "PLSS / TLSS",
    //     node: plssTlssNode
    // }
    
    const handleWellToggled = (checked:boolean) => {
        dispatch(toggleWells(checked));
    }

    const handleRigsToggled = (checked:boolean) => {
        dispatch(toggleRigs(checked));
    }

    const handleAoiToggled = (checked:boolean) => {
        dispatch(toggleAoi(checked));
    }

    const handlePlssTlssToggled = (checked:boolean) => {
        dispatch(togglePlssTlss(checked));
    }

    const handleCountiesToggled = (checked:boolean) => {
        dispatch(toggleCounties(checked));
    }

    const renderedSymbols = Renderers.wellsByTypeRendererPlatform.uniqueValueInfos.map(valueInfo => {
        const { value, label, symbol } = valueInfo;
        if ( value !== -100 ) {
            const node = document.createElement("div");
            const nodeClasses =
                currentFilterLayers && currentFilterLayers.indexOf(value) !== -1
                    ? "wellTypeFilterListItem"
                    : "wellTypeFilterListItem";
            node.setAttribute("class", nodeClasses);
            const imgSpan = document.createElement("span");
            imgSpan.setAttribute("class", "wellTypeIcon");
            renderPreviewHTML(symbol, {
                node: imgSpan,
                size: 8
            });
            node.append(imgSpan);

            const labelSpan = document.createElement("span");
            labelSpan.setAttribute("class", "wellTypeLabel");
            labelSpan.append(label);
            node.append(labelSpan);
            return {
                value,
                label,
                node
            };
        }
    });
    return (
        <div>
            <hr></hr>
            <ul className="wellTypeFilterList">
                <li className="filterHeader">
                    <span className="filterHeaderLabel">WELL STATUS</span>
                    <Switch className="filterToggle" height={17} width={34} checkedIcon={false} uncheckedIcon={false} checked={wellsEnabled} onChange={handleWellToggled}></Switch>
                </li>
                {renderedSymbols.map(renderedSymbol => {
                    if (renderedSymbol != null) {
                        return (
                            <li
                                key={renderedSymbol.value}
                                className="wellTypeFilterListItem"
                                onClick={e => wellItemClicked(renderedSymbol.value)}
                                ref={ele => {
                                    if (ele) {
                                        ele.innerHTML = "";
                                        ele.appendChild(renderedSymbol.node);
                                    }
                                }}
                            />
                        );
                    }
                })}
            </ul>
            <hr></hr>
            <ul className="wellTypeFilterList">
                <li className="filterHeader">
                    <span className="wellTypeFilterListItem" ref={ele => { if (ele) { ele.innerHTML = ""; ele.appendChild(rigSymbol.node)}}} />
                    <Switch className="filterToggle" height={17} width={34} checkedIcon={false} uncheckedIcon={false} checked={rigsEnabled} onChange={handleRigsToggled}></Switch>
                </li>
            </ul>
            <hr></hr>
            <ul className="wellTypeFilterList">
                <li className="filterHeader">
                <span className="wellTypeFilterListItem" ref={ele => { if (ele) { ele.innerHTML = ""; ele.appendChild(aoiSymbol.node)}}} />
                    <Switch className="filterToggle" height={17} width={34} checkedIcon={false} uncheckedIcon={false} checked={aoiEnabled} onChange={handleAoiToggled}></Switch>
                </li>
            </ul>
            {/* <hr></hr>
            <ul className="wellTypeFilterList">
                <li className="filterHeader">
                <span className="wellTypeFilterListItem" ref={ele => { if (ele) { ele.innerHTML = ""; ele.appendChild(plssTlssSymbol.node)}}} />
                    <Switch className="filterToggle" height={17} width={34} checkedIcon={false} uncheckedIcon={false} checked={plssTlssEnabled} onChange={handlePlssTlssToggled}></Switch>
                </li>
            </ul> */}
            <hr></hr>
            <ul className="wellTypeFilterList">
                <li className="filterHeader">
                <span className="wellTypeFilterListItem" ref={ele => { if (ele) { ele.innerHTML = ""; ele.appendChild(countySymbol.node)}}} />
                    <Switch className="filterToggle" height={17} width={34} checkedIcon={false} uncheckedIcon={false} checked={countiesEnabled} onChange={handleCountiesToggled}></Switch>
                </li>
            </ul>
        </div>
    );
}

export default WellTypeFilterPanel;
