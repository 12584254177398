import React from "react";
import {
    ApiListTableFormData,
    OptionType,
    PotentialWellMatchOption,
    csvApiDataObj,
} from "../models/submit-form";
import AsyncSelect from "../common/AsyncSelect";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { GroupBase, OptionsOrGroups } from "react-select";
import { Control, FieldErrors, UseFormSetValue } from "react-hook-form";
import { fetchSuggestionWellName } from "../store/actions/cart-select-basin-county-actions";
import { setCsvApiFileData } from "../store/actions/cart-basin-to-filter-actions";
import { showCheckOutModal } from "../store/actions/modal-actions";
import { addAllBasinOrCountyToCart } from "../store/actions/cart-basin-to-county-actions";
import { AxiosError } from "axios"
import { errToast } from "../../utils/helper";

const CartBasinApiCols = (
    control: Control<ApiListTableFormData, any>,
    errors: FieldErrors<ApiListTableFormData>,
    setValue: UseFormSetValue<ApiListTableFormData>,
) => {
    const dispatch = useAppDispatch();

    const {
        auth: {
            user: { access_token },
        },
        cartSelectBasinCounty: { cartListItems },
        cartBasinToFilterSlice: { csvApiFileData }
    } = useAppSelector((state) => state);
    const loadOptions = async (
        search: string,
        prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>,
        { page }: any,
        csvDataId: string | number,
    ) => {
        let hasMore = false,
            dataList: OptionType[] = [];
        const dataItem = csvApiFileData.filter((item: csvApiDataObj) => item.id === csvDataId)
        if (!search && dataItem.length > 0 && dataItem[0].potentialMatches && dataItem[0].potentialMatches.length > 0) {
            dataList = dataItem[0].potentialMatches.map((_item: PotentialWellMatchOption) => {
                return {
                    label: `API: ${_item.api} - ${_item.well_name}`,
                    value: _item.api,
                }
            })
        }
        if (search.length >= 2) {
            try {
                const res = await dispatch(
                    fetchSuggestionWellName(access_token, { wellName: search })
                );
                if (res && res.status === 200) {
                    const { data } = res;
                    dataList = data.map((_item) => ({
                        label: `API: ${_item.api} - ${_item.well_name}`,
                        value: _item.api,
                    }));
                }
            } catch (err) {
                (err as AxiosError)["code"] !== "ERR_CANCELED" && errToast(err as AxiosError);
            }


        }
        return {
            options: dataList,
            hasMore: hasMore,
            additional: {
                page: page + 1,
            },
        };
    };
    const getMatchPercentage = (dataObj: csvApiDataObj) => {
        if (dataObj.status === "no match") {
            return "-"
        }
        if (dataObj.potentialMatches && dataObj.potentialMatches.length > 0) {
            const wellInOptions = dataObj.potentialMatches.filter((potentialMatch: PotentialWellMatchOption) => potentialMatch.api === dataObj.api)
            if (wellInOptions.length > 0) {
                return wellInOptions[0].match_percentage
            }
        }
        return "100"
    }
    return [
        {
            title: "#",
            thId: "id_col",
            tdClassName: "id_col",
            render: (props: csvApiDataObj, index: number) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            title: "API",
            thId: "api_col_head",
            tdClassName: "api_col",
            render: (props: csvApiDataObj) => {
                return <span>{props.api === "na" ? "-" : props.api}</span>;
            },
        },
        {
            title: "Well Name",
            thId: "api_col_head",
            tdClassName: "maxWidthWellName",
            render: (props: csvApiDataObj) => {
                return (
                    <span data-tooltip-id={`well-name-tooltip-${props.well_name}`}
                        data-for={`well-name-tooltip=${props.well_name}`}
                        data-tooltip-position-strategy="fixed"
                        data-tooltip-html={props.well_name || ""}
                        data-tooltip-place="bottom-start"
                        data-tooltip-variant="light">{props.well_name ? props.well_name : "-"}</span>)
            },
        },
        {
            title: "COUNTY",
            thId: "county_col_head",
            tdClassName: "county_col",
            render: (props: csvApiDataObj) => {
                return <span>{props.county}</span>;
            },
        },
        {
            title: "STATE",
            thId: "state_col_head",
            tdClassName: "state_col",
            render: (props: csvApiDataObj) => {
                return <span>{props.state}</span>;
            },
        },
        {
            title: "STATUS",
            thId: "status_col_head",
            tdClassName: "status_col",
            render: (props: csvApiDataObj) => {
                return (
                    <span
                        className={
                            props.status === "no match"
                                ? "unmatched"
                                : props.status === "matched"
                                    ? "matched"
                                    : props.status === "not in plan"
                                        ? "unsubscribed"
                                        : ""
                        }
                    >
                        <i className="fa-solid fa-circle-exclamation"></i>{" "}
                        {props.status === "no match"
                            ? "Unmatched"
                            : props.status}
                    </span>
                );
            },
        },
        {
            title: "MATCH %",
            thId: "status_col_head",
            tdClassName: "status_col",
            render: (props: csvApiDataObj) => {
                return (
                    <span>{getMatchPercentage(props)}</span>
                );
            },
        },
        {
            title: "WELL MATCHING",
            thId: "well_matching_col_head",
            tdClassName: "well_matching_col",
            render: (props: csvApiDataObj, index: number) => {
                return (
                    <div className="wellmatching" key={props.id}>
                        {props.status !== "not in plan" ? (
                            <React.Fragment key={props.id}>
                                <AsyncSelect
                                    name={`wellMatching[${index}].name`}
                                    key={props.id}
                                    debounceTimeout={500}
                                    placeholder="Choose a well"
                                    control={control}
                                    isMulti={true}
                                    className={
                                        props.status === "matched"
                                            ? "active"
                                            : ""
                                    }
                                    errorMsg={
                                        Array.isArray(errors.wellMatching)
                                            ? errors.wellMatching[index]?.[
                                            "name"
                                            ]?.["message"]
                                            : ""
                                    }
                                    value={props.wellMatching}
                                    additional={{
                                        page: 1,
                                    }}
                                    onChange={(val: OptionType[]) => {
                                        setValue(
                                            `wellMatching.${index}.name`,
                                            val.length === 0 ? null : val
                                        );

                                        const newData = csvApiFileData.map(
                                            (item) =>
                                                props.id === item.id
                                                    ? {
                                                        ...item,
                                                        wellMatching:
                                                            val.length ===
                                                                0
                                                                ? null
                                                                : val,
                                                        ...(val.length ===
                                                            0 &&
                                                            item.status ===
                                                            "matched" && {
                                                            status: "no match",
                                                        }),
                                                        ...(item.status ===
                                                            "no match" &&
                                                            val.length >
                                                            0 && {
                                                            status: "matched",
                                                        }),
                                                        ...(val.length >
                                                            0 && (!item.api || item.api === "na") && { api: val[0].value.toString() }),
                                                        ...(val.length === 0 && item.api !== "na" && item.potentialMatches && item.potentialMatches.length > 0 && { api: "na" })
                                                    }
                                                    : item
                                        )
                                        dispatch(setCsvApiFileData(newData))
                                    }}
                                    components={{ DropdownIndicator: null }}
                                    closeMenuOnSelect={false}
                                    isClearable={false}
                                    backspaceRemovesValue={false}
                                    loadOptions={async (search: string,
                                        prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>,
                                        { page }: any) => await loadOptions(search, prevOptions, page, props.id)}
                                />
                                {Array.isArray(props.wellMatching) &&
                                    props.wellMatching.length > 0 && (

                                        <button
                                            type="button"
                                            className="btn btn-del"
                                        >
                                            <i
                                                className="fa-solid fa-trash"
                                                onClick={() => {
                                                    setValue(
                                                        `wellMatching.${index}.name`,
                                                        null
                                                    );
                                                    dispatch(setCsvApiFileData(csvApiFileData.map(
                                                        (item) =>
                                                            props.id ===
                                                                item.id
                                                                ? {
                                                                    ...item,
                                                                    wellMatching:
                                                                        null,
                                                                    status: "no match",
                                                                }
                                                                : item
                                                    ),))
                                                }}
                                            ></i>
                                        </button>

                                    )}
                            </React.Fragment >
                        ) : (
                            <button
                                type="button"
                                className={
                                    !JSON.stringify(cartListItems).includes(
                                        JSON.stringify(props.county)
                                    )
                                        ? "btn btn-addplan"
                                        : "btn btn-addedplan"
                                }
                                onClick={() => {
                                    if (
                                        !JSON.stringify(cartListItems).includes(
                                            JSON.stringify(props.county)
                                        )
                                    ) {
                                        dispatch(
                                            addAllBasinOrCountyToCart(
                                                access_token,
                                                {
                                                    cat: "county",
                                                    search: [
                                                        {
                                                            state_abbr:
                                                                props.state,
                                                            county: props.county,
                                                        },
                                                    ],
                                                }
                                            )
                                        ).then((res) => {
                                            const { status } = res;
                                            if (status === 200) {
                                                dispatch(showCheckOutModal());
                                            }
                                        });
                                    } else {
                                        dispatch(showCheckOutModal());
                                    }
                                }}
                            >
                                {!JSON.stringify(cartListItems).includes(
                                    JSON.stringify(props.county)
                                ) ? (
                                    "Add to my plan"
                                ) : (
                                    <>
                                        {" "}
                                        <span>
                                            <i className="fa-solid fa-circle-exclamation"></i>
                                        </span>
                                        &nbsp;Added
                                    </>
                                )}
                            </button>
                        )}
                    </div>
                );
            },
        },
    ];
};

export default CartBasinApiCols;
