import React, { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import {
    getAnalyticsData,
    handleAnalyticsData,
    handleAnalyticsProdFilters,
    handleNormalize,
    handleResizableWidth,
    toggleViewAnalytics,
    getProductionDataByLease,
    clearSelectedWellsRigs,
    handleSelectedWellRigOrPermitList,
    setMonthlyDataLoading,
    setCumDataLoading
} from "../store/actions/wells-rigs-action";
import { ProductionDataObj, RigsDataObj, WellsAndPermitsObject } from "../models/redux-models";
import {
    ANALYTICS_CUM_TAB, GAS, LINE_CHART_MAX_ITEM_ALLOWED, ANALYTICS_MONTHLY_TAB, OIL,
    LINE_CHART_XAXIS_FILTERS, VERTICAL, DropDownOption, capitalize, ANALYTICS_DEFAULT_WIDTH,
} from "../../utils/helper";
import { toast } from "react-toastify";
import AnalyticsRightSidebarProductionTab from "./AnalyticsRightSidebarProductionTab"
import AnalyticsRightSidebarWellAttributesTab from "./AnalyticsRightSidebarWellAttributesTab"
import { getSelectedWell } from "./AoiUtils"


const AnalyticsRightSidebar = ({ resizableWidth, draggableRef, startDrag }: { resizableWidth: number, draggableRef: React.RefObject<HTMLDivElement>, startDrag: (event: React.MouseEvent<HTMLDivElement>) => void }) => {
    const {
        wellsAndRigs: {
            viewAnalytics,
            tabIndex,
            selectedRowId,
            analyticsData: { monthlyDataLoading, cumDataLoading, type, xAxisFilter, xAxisFilterCum, normalized, action, action_cum, apiList, apiListObj, forecastingData: { dataList: forecastingData } },
            selectedWellRigOrPermitList,
            analyticsTabIndex,
            wellsData: { data: wellsDataList },
            rigsData: { data: rigsDataList },
            permitsData: { data: permitsDataList },
            selectedWellRigOrPermitUIDList
        },
        auth: { user: { access_token } },
    } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();

    {/* The goal here is have the sidebar open with well information when:
        * the well is clicked in the table 
        * the well is clicked on the map
        
        The first condition is handled in the components/common/WellsAndRigs/Filter
        whereas, the second condition is handled when selectedWellRigOrPermitUIDList is updated via a map click. 
        We hook into this state change here
         */}
    const [oldSelectedWellApiLease, setOldSelectedWellApiLease] = useState<string | number>(0);

    useEffect(() => {
        let selectedWell: WellsAndPermitsObject | null = null
        if (selectedWellRigOrPermitUIDList.length === 1) {
            selectedWell = getSelectedWell(wellsDataList, selectedWellRigOrPermitUIDList[0])
        }
        if (selectedWell?.well_api && selectedWell?.well_api !== oldSelectedWellApiLease) {
            setOldSelectedWellApiLease(selectedWell?.well_api)
            dispatch(getProductionDataByLease(selectedWell?.well_api))
            if (analyticsTabIndex === 1) {
                dispatch(getAnalyticsData(access_token, { api_id: [getSelectedWell(wellsDataList, selectedWellRigOrPermitUIDList[0] || '')?.well_api ?? ''], type: "monthlyTab", action, XAxis: xAxisFilter }, false));
            }
        }
    }, [wellsDataList, selectedWellRigOrPermitUIDList])

    useEffect(() => {
        // We should not show the Analytics sidebar if there are no wells in the list.  However,
        // we should show the sidebar if the selected tab is not 0 to show well attributes
        if (selectedWellRigOrPermitList.length === 0 && viewAnalytics && analyticsTabIndex === 0) {
            dispatch(toggleViewAnalytics());
            dispatch(handleResizableWidth(ANALYTICS_DEFAULT_WIDTH));
            return
        }

        if (selectedWellRigOrPermitList.length > 0 && viewAnalytics) {
            // let tempList = (selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED ? [...selectedWellRigOrPermitList].slice(0, LINE_CHART_MAX_ITEM_ALLOWED) : selectedWellRigOrPermitList)
            let tempList = selectedWellRigOrPermitList

            if (JSON.stringify(tempList) === JSON.stringify(apiListObj)) {
                return
            }

            let tempApiListObjLength = tempList.filter((_item) => {
                return tabIndex === 1 ? (_item as RigsDataObj)['profile']?.toLocaleLowerCase() === VERTICAL : (_item as WellsAndPermitsObject)["drill_type"]?.toLocaleLowerCase() === VERTICAL
            }).length
            // we need to set the loading to true to get the updated production from the getAnalyticsData call
            // in the useEffect below that triggers after an update to the apiList
            dispatch(setMonthlyDataLoading(true))
            dispatch(setCumDataLoading(true))
            dispatch(handleAnalyticsProdFilters({
                apiList: tempList?.map((item) => (tabIndex === 0 || tabIndex === 2) ? (item as WellsAndPermitsObject).well_api : tabIndex === 3 ? (item as ProductionDataObj).well_api : (item as RigsDataObj).api),
                apiListObj: tempList,
                apiListObjLength: tempApiListObjLength
            }))

            if (selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED) {
                setState((prev) => ({
                    ...prev,
                    // showDropDownOption: true,
                    closeOilMaxMsg: false, closeGasMaxMsg: false
                }))
            }
            dispatch(handleAnalyticsProdFilters({ action: selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED ? 'average' : 'none', type }));
            ((tempApiListObjLength && normalized) || tempApiListObjLength) && toast.info("Your selection includes vertical wells.  These are not included in the normalization calculation.")
            tempList.length === tempApiListObjLength && dispatch(handleNormalize(false))

        }
        // eslint-disable-next-line
    }, [selectedWellRigOrPermitList, viewAnalytics])

    const [state, setState] = useState<{
        //  dowData: DowData[], 
        showDropDownOption: boolean, closeOilMaxMsg: boolean, closeGasMaxMsg: boolean
    }>({
        //  dowData: [],
        showDropDownOption: false, closeOilMaxMsg: false, closeGasMaxMsg: false
    })

    const {
        // dowData,
        showDropDownOption, closeOilMaxMsg, closeGasMaxMsg } = state;

    useEffect(() => {
        if (apiList.length) {
            // if (apiList.length > LINE_CHART_MAX_ITEM_ALLOWED && (action === "none" && action_cum === "none")) {
            //     return
            // }
            if (analyticsTabIndex === 0) {
                //only selecting number (LINE_CHART_MAX_ITEM_ALLOWED) max allowed items api to send to backend
                // monthlyDataLoading && type === ANALYTICS_MONTHLY_TAB && dispatch(getAnalyticsData(access_token, { api_id: apiList, type: ANALYTICS_MONTHLY_TAB, action }, selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED ? true : false));

                // cumDataLoading && type === ANALYTICS_CUM_TAB && dispatch(getAnalyticsData(access_token, { api_id: apiList, type: ANALYTICS_CUM_TAB, action: action_cum }, selectedWellRigOrPermitList.length > LINE_CHART_MAX_ITEM_ALLOWED ? true : false))

                monthlyDataLoading && type === ANALYTICS_MONTHLY_TAB && dispatch(getAnalyticsData(access_token, { api_id: apiList, type: ANALYTICS_MONTHLY_TAB, action, XAxis: xAxisFilter }, false));

                cumDataLoading && type === ANALYTICS_CUM_TAB && dispatch(getAnalyticsData(access_token, { api_id: apiList, type: ANALYTICS_CUM_TAB, action: action_cum, XAxis: xAxisFilterCum }, false))


                // donutChartDataLoading && dispatch(getAnalyticsDonutData(access_token, { api_id: apiList }))

                return
            }


        }

        // eslint-disable-next-line    
    }, [wellsDataList, selectedRowId, JSON.stringify(apiList), analyticsTabIndex, type, action, action_cum, xAxisFilter, xAxisFilterCum])


    return (
        <>
            <div className={`${viewAnalytics ? "analyticsCon open" : "d-none"}`}
                onClick={() => {
                    showDropDownOption && setState((prev) => ({ ...prev, showDropDownOption: false }))
                }}
                id="Resizable2"
                style={{
                    ...({ width: `${100 - (resizableWidth)}%` }),
                }}
            >
                <div className="column-full-height">
                    <div
                        ref={draggableRef}
                        onMouseDown={startDrag}
                        className="draggableEle"
                    ></div>
                    <div className="analytics-header">{analyticsTabIndex === 0 ? (<span><img src="images/analytics.svg" alt="" /> Analytics</span>) : (<span>
                        <img src="images/pumpjack.svg" alt="Pumpjack" className="margin-bottom-point-3" /> Well Attributes
                    </span>)}

                        <span className="close-btn"
                            onClick={(e) => {
                                if (analyticsTabIndex === 1) {
                                    dispatch(clearSelectedWellsRigs())
                                } else {
                                    dispatch(toggleViewAnalytics(false))
                                }
                                dispatch(handleResizableWidth(ANALYTICS_DEFAULT_WIDTH));

                            }}
                        >
                            <i className="fa-solid fa-xmark" />
                        </span>
                    </div>
                    {analyticsTabIndex === 0 ? <AnalyticsRightSidebarProductionTab /> :
                        analyticsTabIndex === 1 ? <AnalyticsRightSidebarWellAttributesTab /> : <></>}</div>
            </div>

        </>
    );
};

export default AnalyticsRightSidebar;
