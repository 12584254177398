import { BasinDetailsObject, CountyDetailsObject } from "../../models/redux-models";

const MAXQUERYTOTAL = 2000;

export const utilityCartCheck = (location: { attributes: any}, cartListItems: any) => {
    //checks the item for national in the cart, then if it's a basin and in the cart, or if it's a county and in the cart.
        const isNational = cartListItems.find((item:BasinDetailsObject) => 
            item.line_item_type === 3);
        const isBasinCart = cartListItems.find((item:any) => 
            (!item.hasOwnProperty("county_name") && (item as BasinDetailsObject).basin_name === location.attributes.basin_name));
        const isCountyCart = cartListItems.find((item:any) =>
            (item.hasOwnProperty("county_name") && 
                (item as CountyDetailsObject).county_name === location.attributes.county_name &&
                (item as CountyDetailsObject).state_abbr === location.attributes.state_abbr));
        return isNational || isBasinCart || isCountyCart;
}

export const queryAllFeatures = (cLayer: any, query: any) => {
    const queryPromise: Promise<{features: any[]}> = new Promise((resolve) => {
        cLayer.queryFeatureCount(query).then((count:number) => {
            const queries = [];
            const tmpResults:any[] = [];
            const numQueries = Math.ceil(count / MAXQUERYTOTAL); 
            for(let x=0; x < numQueries; x++) {
                const q = {...query, start: x*MAXQUERYTOTAL, num: MAXQUERYTOTAL};
                console.log(q);
                queries.push(cLayer.queryFeatures(q));
            }
            Promise.all(queries)
            .then((results) => {
                results.forEach((featureSet: { features: any[]; }) => {
                    if (featureSet.features.length > 0) {
                        tmpResults.push(...featureSet.features);
                    }
                });
                resolve({features: tmpResults});
            }).catch((e:any) => {
                console.log("Error getting data");
                resolve({features: tmpResults});
            });
        })
        .catch((e:any) => {
            console.log("Error getting data count");
            resolve({features: []});
        });
    });
    return queryPromise
}

export const queryLayers = (layers: any[], point: any, query: any) => {
    const clauseQuery = query.where && query.where.length > 0;
    const queries:any[] = []
    layers.forEach((l) => queries.push(queryAllFeatures(l, query)));
    const queryPromise: Promise<{features: any[]}[]> = new Promise((resolve) => {
        Promise.all(queries)
        .then((results: any) => {
            resolve(results);
        })
        .catch((err)=> {
            resolve([{features: []}]);
        });
    });
    return queryPromise;
}

