
import { DivWithNormalScreen, DivWithFullScreen } from "../components/models/page-props";
import { isInstanceOfWellsAndPermitsObject } from "../components/common/Map/helper"
import { LineChartDataObj } from "../components/models/redux-models";
import { WellsAndPermitsObject, RigsDataObj, ProductionDataObj } from "../components/models/redux-models";
import { ForecastingFormObj } from "../components/models/redux-models";
import { ActionType } from "../components/models/redux-models";
export const enterFullScreen = (targetdiv: DivWithNormalScreen) => {
    if (targetdiv.requestFullscreen) targetdiv.requestFullscreen();
    else if (targetdiv.mozRequestFullScreen) targetdiv.mozRequestFullScreen();
    else if (targetdiv.webkitRequestFullscreen) targetdiv.webkitRequestFullscreen();
    else if (targetdiv.msRequestFullscreen) targetdiv.msRequestFullscreen();
}

export const exitFullScreen = () => {
    const doc = document as DivWithFullScreen;
    if (doc.fullscreen) {
        if (doc.exitFullscreen) doc.exitFullscreen();
        else if (doc.mozCancelFullScreen) doc.mozCancelFullScreen();
        else if (doc.webkitExitFullscreen) doc.webkitExitFullscreen();
        else if (doc.msExitFullscreen) doc.msExitFullscreen();
    }
}
type OriginalData = {
    name: string;
    values: { date: string, price: number | null }[]
};
export const getLineChartData = (
    dataList: {
        name: string;
        values: {
            date: string;
            price: number;
            numValue: number;
        }[];
    }[] | null,
    normalized: boolean,
    tabIndex: 0 | 1 | 2 | 3,
    type: "monthlyTab" | "cumTab",
    ANALYTICS_MONTHLY_TAB: "monthlyTab",
    oil_data: LineChartDataObj[],
    cum_oil_data: LineChartDataObj[],
    usedFor: string,
    OIL: string,
    GAS: string,
    gas_data: LineChartDataObj[],
    cum_gas_data: LineChartDataObj[],
    selectedWellRigOrPermitList: (WellsAndPermitsObject | RigsDataObj | ProductionDataObj)[],
    selectedForecastPoint: ForecastingFormObj[] | null,
    openForeCast: boolean,
    useDateXAxis: boolean,
    action: ActionType,
    action_cum: ActionType,
    apiListObj: (WellsAndPermitsObject | RigsDataObj | ProductionDataObj)[]) => {
    let data: OriginalData[] = dataList
        ? [...dataList.map((item: any) => Object.assign({}, item))]
        : [];


    let nameKey = !normalized
        ? tabIndex === 1
            ? "profile"
            : "well_name"
        : "well_name";

    let originalWellDataList = (
        (type === ANALYTICS_MONTHLY_TAB ? oil_data : cum_oil_data).length > 0 &&
            usedFor === OIL
            ? type === ANALYTICS_MONTHLY_TAB
                ? oil_data
                : cum_oil_data
            : (type === ANALYTICS_MONTHLY_TAB ? gas_data : cum_gas_data).length >
                0 && usedFor === GAS
                ? type === ANALYTICS_MONTHLY_TAB
                    ? gas_data
                    : cum_gas_data
                : []
    )
    let Data = originalWellDataList.map((item: any) => {
        let temp: any = selectedWellRigOrPermitList.filter((obj: any) =>
            JSON.stringify(obj).includes(item.name)
        );
        //selected point of item list
        let tempSelPoint: any = [];
        selectedForecastPoint &&
            selectedForecastPoint.length &&
            openForeCast &&
            (tempSelPoint = selectedForecastPoint.filter((_obj: any) =>
                JSON.stringify(_obj).includes(item.name)
            ));
        if (Array.isArray(tempSelPoint) && !tempSelPoint.length) {
            tempSelPoint = selectedForecastPoint;
        }
        //with the help of nonZero key we are removing the starting null and zero data
        let nonZero = false;
        let tempArray: any = [];
        let tempNumValue = 0;
        item.values.forEach((_item: any, index: any) => {
            if (
                (_item.production_quantity === 0 ||
                    _item.production_quantity === null) &&
                !nonZero
            ) {
            } else {
                !nonZero && (nonZero = true);

                tempArray.push({
                    date: _item.production_date,
                    price: _item.production_quantity,
                    numValue: tempNumValue,
                    //changing the highlight key value if it lies in selected point
                    highlight:
                        !openForeCast ||
                            !selectedForecastPoint ||
                            !selectedForecastPoint.length
                            ? true
                            : tempSelPoint.find(
                                (selPoint: any) =>
                                    `${selPoint["production_quantity"]}` ===
                                    `${_item["production_quantity"]}` &&
                                    `${selPoint[
                                    useDateXAxis || (originalWellDataList.length === 1 && action === "none") ? "production_date" : "producing_month"
                                    ]
                                    }` ===
                                    `${_item[
                                    useDateXAxis || (originalWellDataList.length === 1 && action === "none")
                                        ? "production_date"
                                        : "production_month"
                                    ]
                                    }`
                            )
                                ? true
                                : false,
                });
                tempNumValue = tempNumValue + 1;
            }
        });

        return {
            name:
                selectedWellRigOrPermitList.length &&
                    (type === ANALYTICS_MONTHLY_TAB
                        ? action === "none"
                        : action_cum === "none")
                    ? temp.length && `${nameKey}` in temp[0]
                        ? temp[0][`${nameKey}`]
                        : "NA"
                    : item.name,
            values: tempArray,
        };
    });
    data = [...Data, ...data];
    if (normalized) {
        // if the data is normalized, we need to divide by the lateral length * 1000
        // why is the data price?
        let newData = [...data]
        let i = 0
        data.forEach((dataFullObj) => {
            let newDataValues = [...dataFullObj.values]
            let lat_length: any = null
            if (isInstanceOfWellsAndPermitsObject(apiListObj[i])) {
                lat_length = (apiListObj[i] as any).lateral_length
            }
            if (!lat_length) {
                i += 1
                return
            }
            newDataValues = newDataValues.map((dataObj) => {
                const newDataObj = { ...dataObj }
                if (!dataObj.price) {
                    newDataObj.price = dataObj.price
                } else {
                    newDataObj.price = dataObj.price / (lat_length / 1000)
                }
                return newDataObj
            })
            newData[i].values = newDataValues
            i += 1
        })
        data = newData
    }
    return data
}


type MergedData = {
    name: string;
    values: { date: string, production_quantity_oil: number | null, production_quantity_gas: number | null }[]
};

export const mergeAndSortOilGasData = (oilData: OriginalData[], gasData: OriginalData[]): MergedData[] => {
    const mergedData: MergedData[] = [];

    const wellMap = new Map<string, MergedData>();
    function toFixedIfNecessary(value: number | null, dp: number) {
        if (!value) {
            return value
        }
        return +parseFloat(value.toString()).toFixed(dp);
    }
    // Process oilData
    oilData.forEach(oilWell => {
        const mergedWellData: MergedData = {
            name: oilWell.name,
            values: oilWell.values.map(oilValue => ({
                date: oilValue.date,
                production_quantity_oil: toFixedIfNecessary(oilValue.price, 2),
                production_quantity_gas: null,
            }))
        };
        wellMap.set(oilWell.name, mergedWellData);
    });

    // Process gasData
    gasData.forEach(gasWell => {
        if (wellMap.has(gasWell.name)) {
            const mergedWell = wellMap.get(gasWell.name)!;
            gasWell.values.forEach(gasValue => {
                const existingEntry = mergedWell.values.find(value => value.date === gasValue.date);
                if (existingEntry) {
                    existingEntry.production_quantity_gas = toFixedIfNecessary(gasValue.price, 2);
                } else {
                    mergedWell.values.push({
                        date: gasValue.date,
                        production_quantity_oil: null,
                        production_quantity_gas: toFixedIfNecessary(gasValue.price, 2),
                    });
                }
            });
        } else {
            const newWellData: MergedData = {
                name: gasWell.name,
                values: gasWell.values.map(gasValue => ({
                    date: gasValue.date,
                    production_quantity_oil: null,
                    production_quantity_gas: toFixedIfNecessary(gasValue.price, 2),
                }))
            };
            wellMap.set(gasWell.name, newWellData);
        }
    });

    // Convert the Map to an array and sort the values by date for each well
    wellMap.forEach(mergedWell => {
        mergedWell.values.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        mergedData.push(mergedWell);
    });

    return mergedData;
}
