import { useEffect, useState } from "react";
import CrsModal from "../aoi/CrsModal";
import GlobalModal from "../common/GlobalModal";
import { useAppSelector, useAppDispatch } from "../hooks/redux-hooks";
import { cartBasinProps } from "../models/page-props";
import {
    CartBasinOpenModalAfterAPiModal,
    CartBasinOpenModalAfterShapeFileModal,
} from "./CartBasinOpenModalAfterCsvShapefile";
import { setOpenModalAFterUploadModal, setGeometry } from "../store/actions/cart-basin-to-filter-actions";


const CartBasinModal = (props: cartBasinProps) => {
    const {
        whichFileToOpen
    } = props;
    const {
        modal: { crsModal },
        cartBasinToFilterSlice: { openModalAFterUploadModal,
            file,
        }
    } = useAppSelector((state) => state);
    const [state, setState] = useState({
        file_name: "",
        aoi_name: "",
        filter_data: "",
    });
    const { file_name, aoi_name, filter_data } = state;
    const dispatch = useAppDispatch();

    const handleStateKey = (obj: { [x: string]: string }) => {
        setState((prev) => ({
            ...prev,
            ...obj,
        }));
    };

    useEffect(() => {
        if (filter_data) {
            dispatch(setGeometry(filter_data))
            dispatch(setOpenModalAFterUploadModal(false))
        }
        // eslint-disable-next-line
    }, [filter_data]);
    return (
        <>
            <GlobalModal
                contentClass={whichFileToOpen === 1 ? "shapefile" : "saveAsAoi"}
                show={openModalAFterUploadModal}
                center={true}
                onHide={() =>
                    dispatch(setOpenModalAFterUploadModal(false))
                }
            >
                {whichFileToOpen === 1 ? (
                    <CartBasinOpenModalAfterAPiModal />
                ) : (
                    <CartBasinOpenModalAfterShapeFileModal
                        file={file}
                        handleStateKey={handleStateKey}
                    />
                )}
            </GlobalModal>
            {/* crs modal */}
            {crsModal && (
                <CrsModal
                    file_name={file_name}
                    aoi_name={aoi_name}
                    handleStateKey={handleStateKey}
                />
            )}
        </>
    );
};

export default CartBasinModal;
